import { useEffect, useRef, useState } from "react";
import Pusher from "pusher-js";
import { TokenLocalStorage } from "services/helpers/local-storage";
import { getConfig } from "pusher-js/types/src/core/config";
import Echo from "laravel-echo";


const getPusherConfig = () => {

    const token = TokenLocalStorage.get();

    const config = {
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        wsHost: import.meta.env.VITE_PUSHER_HOST,
        wsPort: import.meta.env.VITE_PUSHER_PORT ? parseInt(import.meta.env.VITE_PUSHER_PORT) : 6001,
        wssPort: import.meta.env.VITE_PUSHER_PORT ? parseInt(import.meta.env.VITE_PUSHER_PORT) : 6001,
        forceTLS: import.meta.env.VITE_PUSHER_FORCE_TLS === 'true',
        disableStats: import.meta.env.VITE_PUSHER_DISABLE_STATS === 'true',
        encrypted: import.meta.env.VITE_PUSHER_ENCRYPTED === 'true',
        enabledTransports: ['ws', 'wss'],
        cluster: import.meta.env.VITE_PUSHER_CLUSTER,
        authEndpoint: "https://" + import.meta.env.VITE_PUSHER_HOST + '/api/broadcasting/auth',
        auth: {
            headers: {
                Authorization: "bearer " + token?.token || "",
                Accept: "application/json"
            }
        }
    };

    return config;

}

const InitializePusher = () => {

    //console.log("Initializing pusher")

    const token = TokenLocalStorage.get();

    if (!token) {
        console.error("No token available to initialize pusher")
        return;
    }

    try {
        const config = getPusherConfig();
        window.Pusher = Pusher;
        window.Echo = new Echo(config);
        window.dispatchEvent(new Event(`pusherUpdate`));
    } catch (e) {
        console.error("Error setting up pusher", e);
    }



}

type useSocketsProps = {
    onMessage?: (message: any) => void;
    channel?: string;
    event?: string;
}


export const useSocketsManager = () => {

    useEffect(() => {
        InitializePusher();
    }, [])

    useEffect(() => {
        window.addEventListener('token', () => {

            const token = TokenLocalStorage.get();

            if (!token) {

                if (window.Echo) {
                    window.Echo.disconnect();
                    window.Echo = null;
                }

            }

            if (!window.Echo) {
                InitializePusher();
            } else {
                if (window?.Echo?.connector?.pusher?.config?.auth?.headers?.Authorization) {   
                    window.Echo.connector.pusher.config.auth.headers.Authorization = "Bearer " + token;
                }
            }


        })
    }, [])

}

const useSockets = (props: useSocketsProps) => {
    const { onMessage, channel, event } = props;
    const subscribedChannel = useRef<string | null>(null);
    const subscribedEvent = useRef<string | null>(null);

    const subscribeToChannel = (channelName: string, eventName: string) => {

        if (!window.Echo) return;

        if (subscribedChannel.current !== channelName || subscribedEvent.current !== eventName) {
            // Unsubscribe from the old channel and event if necessary
            if (subscribedChannel.current && subscribedEvent.current) {
                window.Echo.leave(subscribedChannel.current);
            }

            // Subscribe to the new channel and event
            //console.log("Subscribing to channel", channelName, "event", eventName)
            window.Echo.channel(channelName).listen(eventName, (e) => {
                onMessage && onMessage(e);
            });

            // Update refs with the new channel and event names
            subscribedChannel.current = channelName;
            subscribedEvent.current = eventName;
        }
    };

    useEffect(() => {
        // Subscribe when the component mounts or when channel/event props change
        if (!window.Echo) return;


        subscribeToChannel(channel || "test", event || "testEvent");

        // Unsubscribe when the component unmounts or the props change
        return () => {
            if (!window.Echo) return;

            if (subscribedChannel.current && subscribedEvent.current) {
                window.Echo && window.Echo.leave(subscribedChannel.current);
                subscribedChannel.current = null;
                subscribedEvent.current = null;
            }
        };
    }, [channel, event, onMessage]);

    useEffect(() => {
        window.addEventListener(`pusherUpdate`, () => {
            subscribeToChannel(channel || "test", event || "testEvent");
        });
        subscribeToChannel(channel || "test", event || "testEvent");
    }, [])

};

export default useSockets;
