import { Avatar } from "antd";
import { PhoneFrameIcon } from "modules/panel/components/ft-icons"
import { ExtendPreviewConfig, SimplePreviewConfig } from "modules/bot/components/posts-list-item/options/form/BotBtnConfig";
import "./BotPreviewStyle.less";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentProjectIntegration } from "state/projects/projectSelectors";

type Props = {
    integration?: string
    bot?: any
}

export const BotPreview = (props: Props) => {
    const fanpage = useSelectorWithParams(currentProjectIntegration("service_facebook_page"));

    return (
        <>
        {fanpage !== null && (
            <div className="bot-preview">
                <PhoneFrameIcon className="phone-frame" />
                <div className="screen">
                    <div className="heading">
                        <Avatar src={fanpage.picture} shape="circle" />
                        <span className="name">{fanpage.global_brand_page_name}</span>
                    </div>
                    {props.integration === 'private_replies' ? (
                        <SimplePreviewConfig
                            style={{ width: "100%", height: "100%" }}
                            bot={props.bot}
                        />
                    ) : (
                        <ExtendPreviewConfig
                            style={{ width: "100%", height: "100%" }}
                            integration={props.integration}
                            bot={props.bot}
                        />
                    )}

                </div>
            </div>
        )}
        </>
    )
}