import { Row, Col, Input, AutoComplete, Divider, Alert, Select } from "antd";
import { useEffect, useState, useMemo } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { useDebounceTrigger } from "services/hooks/useDebounce";
import { useRelaxMultiform } from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import Map from "./Map";
import useAPI from "services/hooks/useAPI";
import { StandardRow } from "components/layout/StandardGrid";
import { useGoogleGeocoder } from "services/hooks/useGoogleGeocoder";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { CountrySelect } from "./CountrySelect";
import useRelaxData from "tools/relaxForm/useRelaxData";
import StandardButton from "components/common/StandardButton";
import { provinces } from "./Provinces";
import RelaxFormWrapper from "tools/relaxForm/RelaxForm";
import ZipCodeInput from "_components/forms/masked-inputs/zip-code-input";

type AddressProps = {
  address: string;
  city: string;
  voivodeship: string;
  country: string;
  zip_code: string;
  latitude: number;
  longitude: number;
};

export default function AddressWithMap(props: any) {
  const mf = useRelaxMultiform();
  const initialValuesSelector =
    mf?.multiformProps?.initialValuesSelector || props.initialValuesSelector;
  const initialValues: AddressProps | null = useSelectorWithParams(
    initialValuesSelector
  );

  const locationString = useMemo(() => {
    if (!initialValues) return "";
    if (!initialValues.address) return "";
    return `${initialValues.address || ""} ${initialValues.zip_code || ""} ${
      initialValues.city || ""
    } ${initialValues.country || ""}`;
  }, [initialValues]);

  const rd = useRelaxData({
    finalizeSubmit: (data) => {
      if (results != null && results.length == 0) {
        const geometry = results[0].geometry.location;
        return {
          ...data,
          latitude: geometry?.lat(),
          longitude: geometry?.lng(),
        };
      } else {
        return data;
      }
    },
  });
  const { currentValue, setCurrentValue, submit } = rd;

  const [showAlert, setShowAlert] = useState(false);
  const { search, results, searchByPoint } = useGoogleGeocoder({});
  const [searchWithDebounce] = useDebounceTrigger(search, 500);
  const [tempLocation, setTempLocation] = useState<AddressProps | null>(null);

  useEffect(() => {
    if (locationString === '') return;
    searchWithDebounce(locationString);
  }, [locationString]);

  useEffect(() => {
    if (results && results.length != 0) {
      const geometry = results[0].geometry.location;
      const result = results[0].address_components;

      setCurrentValue({
        address: `${result[1]?.long_name} ${result[0]?.long_name}`,
        city: result[2]?.long_name,
        voivodeship: result[4]?.long_name,
        country: result[5]?.short_name,
        zip_code: result[6]?.long_name,
        latitude: geometry?.lat(),
        longitude: geometry?.lng(),
      });
    }
  }, [results]);

  const marker = useMemo(() => {
    if (!results) return [];
    if (results.length == 0) return [];
    if (showAlert === false && tempLocation !== null) {
      return [
        {
          position: {
            lat: tempLocation.latitude,
            lng: tempLocation.longitude,
          },
          draggable: true,
        },
      ];
    }

    const geometry = results[0].geometry.location;

    return [
      {
        position: {
          lat: geometry?.lat(),
          lng: geometry?.lng(),
        },
        draggable: true,
      },
    ];
  }, [results, showAlert]);

  const regionOptions = useMemo(() => {
    if (provinces[props.country]) {
      return provinces[props.country];
    } else if (initialValues?.country && provinces[initialValues?.country]) {
      return provinces[initialValues.country];
    } else {
      return [];
    }
  }, [props.country, initialValues?.country]);

  const handleMarkerDragEnd = (markerLocation: any) => {
    const { latitude, longitude } = markerLocation;

    searchByPoint(latitude, longitude).then((pointData) => {
      if (pointData && pointData.results && pointData.results.length > 0) {
        const result = pointData.results[0].address_components;
        const geometry = pointData.results[0].geometry.location;

        setTempLocation({
          address:
            currentValue != null
              ? currentValue.address
              : initialValues?.address,
          city: currentValue != null ? currentValue.city : initialValues?.city,
          voivodeship:
            currentValue != null
              ? currentValue.voivodeship
              : initialValues?.voivodeship,
          country:
            currentValue != null
              ? currentValue.country
              : initialValues?.country,
          zip_code:
            currentValue != null
              ? currentValue.zip_code
              : initialValues?.zip_code,
          latitude:
            currentValue != null
              ? currentValue.latitude
              : initialValues?.latitude,
          longitude:
            currentValue != null
              ? currentValue.longitude
              : initialValues?.longitude,
        });

        setCurrentValue({
          address: `${result[1]?.long_name} ${result[0]?.long_name}`,
          city: result[2]?.long_name,
          voivodeship: result[4]?.long_name,
          country: result[5]?.long_name,
          zip_code: result[6]?.long_name,
          latitude: geometry?.lat(),
          longitude: geometry?.lng(),
        });

        setShowAlert(true);
      }
    });
  };

  const handleSetNewLocation = () => {
    setTempLocation(null);
    submit();
    setShowAlert(false);
  };

  const handleDismissNewLocation = () => {
    setCurrentValue({
      address: initialValues?.address,
      city: initialValues?.city,
      voivodeship: initialValues?.voivodeship,
      country: initialValues?.country,
      zip_code: initialValues?.zip_code,
      latitude: initialValues?.latitude,
      longitude: initialValues?.longitude,
    });
    setShowAlert(false);
  };

  const isDisabledInputs = initialValues?.country != null ? false : true;

  return (
    <>
      <RelaxFormWrapper relaxData={rd}>
        <StandardRow
          align="middle"
          type="flex"
          style={{ alignItems: "stretch" }}
        >
          <Col xs={24} md={12} xl={8}>
            <FormattedMessage
              id="apps.websites.forms.companyData.address"
              defaultMessage="Address"
            />
            <Divider />
            <RelaxSingleField
              name="country"
              shouldReset={true}
              required
              label={
                <FormattedMessage
                  id="apps.websites.forms.companyData.country"
                  defaultMessage="Country"
                />
              }
            >
              <CountrySelect />
            </RelaxSingleField>

            {initialValues?.country && (
              <RelaxSingleField
                name="voivodeship"
                shouldReset={true}
                required
                label={
                  <FormattedMessage
                    id="apps.websites.forms.companyData.region"
                    defaultMessage="region"
                  />
                }
              >
                {regionOptions && regionOptions.length > 0 ? (
                  <Select options={regionOptions} />
                ) : (
                  <Input/>
                )}
              </RelaxSingleField>
            )}

            <RelaxSingleField
              name="city"
              shouldReset={true}
              required
              disabled={isDisabledInputs}
              label={
                <FormattedMessage
                  id="apps.websites.forms.companyData.city"
                  defaultMessage="City"
                />
              }
            >
              <Input />
            </RelaxSingleField>

            <RelaxSingleField
              name="address"
              shouldReset={true}
              required
              disabled={isDisabledInputs}
              label={
                <FormattedMessage
                  id="apps.websites.forms.companyData.address"
                  defaultMessage="Address"
                />
              }
            >
              <Input />
            </RelaxSingleField>

            <Row gutter={30}>
              <Col xs={24} md={12} lg={12}>
                <RelaxSingleField
                  name="zip_code"
                  shouldReset={true}
                  required
                  disabled={isDisabledInputs}
                  label={
                    <FormattedMessage
                      id="apps.websites.forms.companyData.zipCode"
                      defaultMessage="Zip code"
                    />
                  }
                >
                  <ZipCodeInput />
                </RelaxSingleField>
              </Col>
              <Col xs={24} md={12} lg={12}></Col>
            </Row>
          </Col>
          <Col xs={24} md={12} xl={16}>
            <Map
              markers={marker}
              height="100%"
              onMarkerLocationChange={handleMarkerDragEnd}
            />
            {showAlert && (
              <Alert
                message={
                  <>
                    <FormattedMessage
                      id="apps.websites.forms.companyData.new.location"
                      defaultMessage="Google find new address on marker:"
                    />
                    <ul>
                      <li>{`address: ${currentValue?.address}`}</li>
                      <li>{`city: ${currentValue?.city}`}</li>
                      <li>{`voivodeship: ${currentValue?.voivodeship}`}</li>
                      <li>{`country: ${currentValue?.country}`}</li>
                      <li>{`zip_code: ${currentValue?.zip_code}`}</li>
                    </ul>
                  </>
                }
                description={
                  <>
                    <FormattedMessage
                      id="apps.websites.forms.companyData.new.location.confiramtion"
                      defaultMessage="You want new location?"
                    />
                    <div>
                      <StandardButton onClick={handleDismissNewLocation}>
                        <FormattedMessage
                          id="apps.websites.forms.companyData.new.location.no"
                          defaultMessage="No"
                        />
                      </StandardButton>

                      <StandardButton onClick={handleSetNewLocation}>
                        <FormattedMessage
                          id="apps.websites.forms.companyData.new.location.yes"
                          defaultMessage="Yes"
                        />
                      </StandardButton>
                    </div>
                  </>
                }
                type="info"
                style={{
                  position: "absolute",
                  zIndex: 9999,
                  top: "10px",
                  right: "10px",
                }}
              />
            )}
          </Col>
        </StandardRow>
      </RelaxFormWrapper>
    </>
  );
}
