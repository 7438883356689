import { Input, Radio } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import DateTimeInput from "components/inputs/DateTimeInput";
import { StandardModal, useSimpleModal, useStandardModal } from "services/hooks/useModal";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxFormWrapper from "tools/relaxForm/RelaxForm";
import AIStrategySelect from "./AIStrategySelect";
import AIStrategyAndIntensitySelect from "./AIStrategyAndIntensitySelect";
import useRelaxData from "tools/relaxForm/useRelaxData";
import { useEffect, useState } from "react";
import useAPI from "services/hooks/useAPI";
import dayjs, { Dayjs } from "dayjs";
import DatePicker, { WeekPicker } from "components/inputs/DatePicker";
import { rulesetMax } from "tools/relaxForm/relaxPresets";

const AICampaignGenerationModal = (props: any) => {

    const [modalRef, open, close] = useStandardModal();
    const rd = useRelaxData({});
    const [openSuccessModal, contextHolder] = useSimpleModal();
    
    const generateCall = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/ai/campaign`),
    })

    const handleGenerate = () => {

        const generationData = {
            start: dayjs().utc().startOf("week").add(1, "week").format("YYYY-MM-DD HH:mm:ss"),
            comment: "Simple ad promoting my page",
            ...rd?.currentValue,
        }

        if (rd?.currentValue?.start) generationData.start = dayjs(rd?.currentValue?.start).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        
        generateCall.call({
            url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/ai/campaign`),
            method: "POST",
            body: { ...generationData }
        }).then((res) => {

            if (res?.status === 200) {
                openSuccessModal({
                    title: "Success",
                    content: <FormattedMessage
                        id="apps.smart.components.aiGeneration.createCampaignForm.success.message"
                        defaultMessage="Your creations are being generated"
                    />,
                    onOk:close
                })
            } else {
                openSuccessModal({
                    title: "Error",
                    content: <FormattedMessage
                        id="apps.smart.components.aiGeneration.createCampaignForm.error.message"
                        defaultMessage="There was an error while generating creations."
                />,
                })
            }

        })
    }

    return (
        <>
            <StandardModal
                ref={modalRef}
                modalProps={{
                    footer: 
                        <StandardButton
                            onClick={() => {
                                handleGenerate()
                            }}
                            
                disabled={ rd.extraStatus === "fieldErrors"}
                            type="primary"
                            loading={generateCall.loading}
                        >
                            <FormattedMessage
                        id="apps.smart.components.aiGeneration.generateButton"
                        defaultMessage="Generate!"
                    />
                        </StandardButton>
                }}
                title={
                    <FormattedMessage
                        id="apps.smart.components.aiGeneration.title"
                        defaultMessage="Generate with AI"
                    />
                }
            >
                <RelaxFormWrapper
                    relaxData={rd}
                    name="form"
                    hideStatus={true}
                    mode="manual"
                >
                    <RelaxField
                        name="start"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.createCampaignForm"
                                defaultMessage="Start time"
                            />
                        }
                        initialValue={dayjs().startOf("week").add(1, "week")}
                    >
                        <WeekPicker 
                            disabledDate={(current: Dayjs) => {
                                return current.isBefore(dayjs().startOf("week").add(1, "week"))
                            }}
                        />
                    </RelaxField>
                    <AIStrategyAndIntensitySelect />
                    {/* <RelaxField
                        name="style"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.style"
                                defaultMessage="Style"
                            />
                        }
                    >
                        <AIStyleSelect />
                    </RelaxField> */}
                    <RelaxField
                        name="generate_images"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.graphics"
                                defaultMessage="Graphics"
                            />
                        }
                        initialValue={true}
                    >
                        <Radio.Group defaultValue={true} style={{
                            display: "flex"
                        }}>
                            <Radio.Button value={true} type="primary" style={{ flexGrow: 1, display: "grid", placeItems: "center" }}>
                                <FormattedMessage
                                    id="apps.smart.components.aiGeneration.graphics.ai"
                                    defaultMessage="Generate with AI"
                                />
                            </Radio.Button>
                            <Radio.Button value={false} style={{ flexGrow: 1, display: "grid", placeItems: "center" }}>
                                <FormattedMessage
                                    id="apps.smart.components.aiGeneration.graphics.own"
                                    defaultMessage="Upload my own"
                                />
                            </Radio.Button>
                        </Radio.Group>
                    </RelaxField>
                    <RelaxField
                        name="comment"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.instructions"
                                defaultMessage="Instructions"
                            />
                        }
                        rules={rulesetMax(160)}
                        >
                        <Input.TextArea />
                    </RelaxField>
                </RelaxFormWrapper>
            </StandardModal>
            <StandardButton
                onClick={open}
                type="primary"
            >
                <FormattedMessage
                    id="apps.smart.components.aiGeneration.buttonToOpenTheModal"
                    defaultMessage="Generate with AI"
                />
            </StandardButton>
            {contextHolder}
        </>
    )


}

export default AICampaignGenerationModal;