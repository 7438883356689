import FormattedMessage from "components/common/FormattedMessage";
import { PlusIcon } from "modules/panel/components/ft-icons";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import StandardButton from "components/common/StandardButton";
import { AddBot, BotProps, GetBot } from "modules/bot/containers/BotsSlice";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import { ReactNode, useMemo, useRef, useState } from "react";
import { BotCreate } from "./form/BotCreate";
import RelaxFormWrapper from "tools/relaxForm/RelaxForm";
import ButtonWithIcon from "modules/smart/components/list-item/base-list-item/ButtonWithIcon";
import { RelaxValidationResults } from "tools/relaxForm/RelaxValidationResults";

type Props = {
    postId?: string
    btn?: ReactNode
}

export const CreateBotBtn = (props: Props) => {
    const [botBasedOn, setBotBasedOn] = useState("all-comments");
    const [integration, setIntegration] = useState<string>('private_replies');
    const [drawerRef, open, close] = useStandardDrawer();
    const getBot = useAPI(GetBot(1))
    const { call: addBot, error } = useAPI(AddBot())
    const [count, setCount] = useState(0)
    const [createdBotData, setCreatedBotData] = useState<Partial<BotProps>>({});

    const insideKey = useMemo(() => {
        return `botCreate-` + count
    }, [count])

    const btnWithOnClick = (
        <StandardButton
            onClick={() => {
                setCreatedBotData({})
                setCount(count + 1)
                open()
            }}
            style={{ marginBottom: "7px" }}>
            {props.btn}
        </StandardButton>
    );
    const handleCreateBot = () => {
        addBot({
            body: props.postId ? { ...createdBotData, page_post_id: props.postId } : createdBotData,
        }).then((res) => {
            if (res.status === 200 || res.status === 201) {
                getBot.call(GetBot(res.data.id)).then((res: any) => {
                    if (res.status === 200) {
                        close();
                    }
                })
            }
        })
    }

    return (
        <>
            {props.btn === undefined ? (
                <ButtonWithIcon
                    icon={<PlusIcon />}
                    onClick={() => {
                        setCreatedBotData({})
                        setCount(count + 1)
                        open()
                    }}>
                    <FormattedMessage
                        id="bot.components.createBot"
                        defaultMessage="Create bot"
                    />
                </ButtonWithIcon>
            ) : (
                btnWithOnClick
            )}

            <StandardDrawer
                ref={drawerRef}
                width={"1300px"}
                layout="standard"
                mask={true}
                title={
                    <FormattedMessage
                        id="bot.components.createBot"
                        defaultMessage="Add Message Bot"
                    />
                }
                zIndex={1000}
                onClose={close}
                footerWidth={"95%"}
                footer={
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <StandardButton onClick={handleCreateBot} type={"primary"}>
                            <FormattedMessage
                                id="modules.bot.form.addBot"
                                defaultMessage="Add bot"
                            />
                        </StandardButton>
                    </div>
                }
            >

                <RelaxFormWrapper
                    submitOverride={(data: any) => {
                        if (data.confirmations) {
                            const message = data.confirmations[0].message ? data.confirmations[0].message : createdBotData.confirmations?.[0]?.message
                            const keyword = data.confirmations[0].keyword ? data.confirmations[0].keyword : createdBotData.confirmations?.[0]?.keyword
                            const error_message = data.confirmations[0].error_message ? data.confirmations[0].error_message : createdBotData.confirmations?.[0]?.error
                            data = {
                                ...data,
                                confirmations: [{ error_message, message, keyword, order: 0 }],
                            };
                        }
                        if (data.integration_group_external_id) {
                            data = {
                                integration_group_external_id: data.integration_group_external_id.toString(),
                                type: integration
                            }
                        }

                        const updatedValue = { ...createdBotData, ...data, activation: botBasedOn }
                        setCreatedBotData(updatedValue)
                    }}
                >
                    <BotCreate
                        key={insideKey}
                        botBasedOn={botBasedOn}
                        setBotBasedOn={setBotBasedOn}
                        integration={integration}
                        setIntegration={setIntegration}
                        createdBotData={createdBotData} />
                </RelaxFormWrapper >
                <RelaxValidationResults validationErrors={error?.errors} />
            </StandardDrawer>

        </>
    )
}

