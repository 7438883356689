import { Dropdown, Menu } from "antd"
import { UpdateBotBtn } from "../../posts-list-item/options/UpdateBotBtn"
import BotOptionsDropdown from "../../posts-list-item/options/BotOptionsDropdown"
import { CreateBotBtn } from "../../posts-list-item/options/CreateBotBtn"
import { CreatePostIcon, DeleteIcon, UpdateIcon } from "modules/panel/components/ft-icons"
import FormattedMessage from "components/common/FormattedMessage"
import "./ManagePostBotsStyle.less";
import ButtonWithIcon from "modules/smart/components/list-item/base-list-item/ButtonWithIcon"

type Props = {
    bots: any,
    postId: string
}

export const ManagePostBots = (props: Props) => {
    return (
        <>
            <Dropdown
                overlay={
                    <Menu>
                        {props.bots.map((bot: any) => (
                            <Menu.Item>
                                <UpdateBotBtn
                                    botId={bot.id}
                                    btn={
                                        <>
                                            <div className="button-content">
                                                <span className="icon">
                                                    <UpdateIcon />
                                                </span>

                                                <div className="text">
                                                    <FormattedMessage
                                                        id="bot.components.createBot.setupBot"
                                                        defaultMessage={"Setup bot: {name}"}
                                                        values={{ name: bot.id }}
                                                    />
                                                </div>

                                                <div className="remove-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }} >
                                                    <BotOptionsDropdown botId={bot.id} withPostControls={false} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                />
                            </Menu.Item>
                        ))}
                        <Menu.Item>
                            <CreateBotBtn
                                postId={props.postId}
                                btn={
                                    <>
                                        <div className="button-content">
                                            <span className="icon" style={{ marginTop: "5px" }}>
                                                <CreatePostIcon />
                                            </span>
                                            <div className="text" style={{ marginLeft: "12px" }}>
                                                <FormattedMessage
                                                    id="bot.components.createBot"
                                                    defaultMessage="Create bot"
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                            />
                        </Menu.Item>
                    </Menu>
                }
            >
                <ButtonWithIcon icon={<UpdateIcon />} filled={false}>
                    <FormattedMessage
                        id="bot.components.createBot.heading"
                        defaultMessage="Edit bot"
                    />
                </ButtonWithIcon>
            </Dropdown>
        </>
    )
}