import { useMemo } from "react";
import { applyCoupon } from "state/subscriptions/subscriptionPricingHelpers";
import { useIntl } from "react-intl";
import FormattedMessage from "components/common/FormattedMessage";
import _ from "lodash";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import { usePurchaseSubscription } from "./PurchaseSubscription";
import { BlankOfferSummary } from "./BlankOfferSummary";

const roundToTwoDigits = (value: number) => _.round(value, 2).toFixed(2);

type OfferPricingSummaryProps = {
    onMoveToPage: (page: string) => void;
    className?: string;
}

const paymentPeriod = (period: any) => {
    if (period === "yearly")
        return (
            <FormattedMessage
                id="panel.containers.payment.summarySidebar.paymentPeriod.yearly"
                defaultMessage="Paid yearly."
            />
        );

    if (period === "monthly")
        return (
            <FormattedMessage
                id="panel.containers.payment.summarySidebar.paymentPeriod.monthly"
                defaultMessage="Paid montly."
            />
        );

    if (period === "oneTime")
        return (
            <FormattedMessage
                id="panel.containers.payment.summarySidebar.paymentPeriod.oneTime"
                defaultMessage="One time payment."
            />
        );

    return "";
};


function OfferPricingSummary(props: OfferPricingSummaryProps) {

    const { offer, promoCodeCheck, partnerCode, promoCode, isPromoCodeValid } = usePurchaseSubscription();
    const { formatNumber } = useIntl();


    const o = useMemo(() => {
        return applyCoupon(offer, promoCodeCheck.discount);
    }, [offer, promoCodeCheck.discount]);

    const formatWithCurrency = (value: number) => formatNumber(value, { style: 'currency', currency: o?.currency });

    const lengthInfo = useMemo(() => {
        if (!o) return null;
        if (o.trial)
            return (
                <FormattedMessage
                    id="panel.containers.payment.summarySidebar.lengthInfo.trial"
                    defaultMessage="Trial subscription"
                />
            );

        if (o.cycle === "yearly")
            return (
                <FormattedMessage
                    id="panel.containers.payment.summarySidebar.lengthInfo.twelveMonthsPeriod"
                    defaultMessage="One year contract, paid annualy"
                />
            );

        if (o.mandatoryPeriods === 12 && o.cycle === "monthly")
            return (
                <FormattedMessage
                    id="panel.containers.payment.summarySidebar.lengthInfo.yearContractEveryMonth"
                    defaultMessage="Contracted for one year, billed monthly"
                />
            );

        if (o.mandatoryPeriods === 1 && o.cycle === "monthly")
            return (
                <FormattedMessage
                    id="panel.containers.payment.summarySidebar.lengthInfo.everyMonth"
                    defaultMessage="The contract is renewed every month"
                />
            );
    }, [o]);

    const montlyPriceOfYearlySubscription = () => {
        if (!o) return null;
        return (
            <>
                <span>
                    <FormattedMessage
                        id="panel.containers.payment.summarySidebar.subscription.monthlyPriceOfYearlySubscription"
                        defaultMessage="{price} per month x 12 months"
                        values={{ price: roundToTwoDigits(o.price / 12) }}
                    />
                </span>
            </>
        )
    }

    const netPriceWithPeriod = (price: any, period: any) => {
        return (
            <>
                <span>
                    <FormattedMessage
                        id="panel.containers.payment.summarySidebar.subscription.priceNet"
                        defaultMessage="Price {price} net."
                        values={{ price: roundToTwoDigits(price) }}
                    />
                </span>
                <span>
                    {" "}{paymentPeriod(period)}
                </span>
            </>
        )
    }


    const withTrial = () => {
        if (!o) return null;

        return (
            <>
                <div className="line">
                    <div className="name">
                        <h3>
                            <FormattedMessage
                                id="panel.containers.payment.summarySidebar.trial"
                                defaultMessage="Trial"
                            />
                        </h3>
                    </div>
                    <div className="value">{formatWithCurrency(o.trial)}</div>
                </div>

                <div className="line">
                    <div className="name">
                        <h3>
                            <FormattedMessage
                                id="panel.containers.payment.summarySidebar.afterTrial"
                                defaultMessage="After the trial period"
                            />
                            <p className="underline">
                                <FormattedMessage
                                    id="panel.containers.payment.summarySidebar.activationFeeWillBeAddedToFirstPayment"
                                    defaultMessage="An activation fee {additionalFee} will be added to the first payment"
                                    values={{
                                        additionalFee: formatWithCurrency(
                                            o.additionalFees ? o.additionalFees.gross : 0
                                        ),
                                    }}
                                />
                            </p>
                        </h3>
                    </div>
                    <div className="value">{formatWithCurrency(o.price)}</div>
                </div>
            </>
        )
    }

    const withSubscription = () => {
        if (!o) return null;
        
        return (
        <>
          <div className="line">
            <div className="name">
              <h3>
                <FormattedMessage
                  id="panel.containers.payment.summarySidebar.subscription"
                  defaultMessage="Subscription"
                />
              </h3>
    
              {
                o.cycle === "yearly" && (
                  <p className="underline">
                    {montlyPriceOfYearlySubscription()}
                  </p>
                )
              }
    
              <p className="underline">
                {netPriceWithPeriod(o.subscription.withDiscount?.net, o.cycle)}
              </p>
    
    
            </div>
            <div className="value">{formatWithCurrency(o.subscription.withDiscount?.gross)}
              {(o.subscription && o.subscription.hasDiscount) && <div className="before-discount">{formatWithCurrency(o.subscription.base.gross)}</div>}
            </div>
          </div>
          {o.additionalFees &&
            o.additionalFees.items.map(({ name, value, gross }) => (
              <div className="line">
                <div className="name">
                  <h3>{name}</h3>
                  <p className="underline">
                    {netPriceWithPeriod(value, "oneTime")}
                  </p>
                </div>
                <div className="value">{formatWithCurrency(gross)}
                </div>
              </div>
            ))}
        </>
      )
    }

    if (!o) return <BlankOfferSummary {...props} />;


    return (
        <div className={`subscription-price-details ${props.className ? props.className : ""}`}>
          <div></div>
    
          <div>
            <div className="heading">
              <h1>
                <FormattedMessage
                  id="panel.containers.payment.summarySidebar.billingDetails"
                  defaultMessage="Billing details"
                />
              </h1>
              {/* <p className="offer-title">{title}</p>
              <p className="length-info">{lengthInfo}</p> */}
              <p className="offer-title">{lengthInfo}</p>
            </div>
            {o.trial ? withTrial() : (o.subscription && withSubscription())}
            <div className="line pay-now">
              <div className="name">
                <h3>
                  <FormattedMessage
                    id="panel.containers.payment.summarySidebar.youPayNow"
                    defaultMessage="You pay now"
                  />
                </h3>
                <p className="underline">
                  <span>
                    <FormattedMessage
                      id="panel.containers.payment.summarySidebar.subscription.priceNet"
                      defaultMessage="Price {price} net."
                      values={{ price: roundToTwoDigits(o.paidNow.net) }}
                    />
                  </span>
                  <span>
                    {" "}
                    <FormattedMessage
                      id="panel.containers.payment.summarySidebar.includesVat"
                      defaultMessage="Includes {taxRate}% tax"
                      values={{ taxRate: o.taxRate * 100 }}
                    />
                  </span>
                </p>
              </div>
              <div className="value">{formatWithCurrency(o.paidNow.gross)}</div>
            </div>
          </div>
    
              <div style={{height: "200px"}}></div>
    
            <div style={{ 
              display: "flex",
              justifyContent: "space-between",
              }}>
              <a href="#code" onClick={() => props.onMoveToPage && props.onMoveToPage("partner")}>
                {
                  partnerCode && partnerCode.length > 0 && (
                    <AwesomeIcon icon={faCheckCircle} size="xl"/>
                  )
                }
                <FormattedMessage
                  id="panel.containers.payment.summarySidebar.partnerCode.open"
                  defaultMessage="Partner code"
                />
              </a>
    
              <a href="#code" onClick={() => props.onMoveToPage && props.onMoveToPage("promo")}>
                {
                  isPromoCodeValid && (
                    <AwesomeIcon icon={faCheckCircle} size="xl"/>
                  )
                }
                <FormattedMessage
                  id="panel.containers.payment.summarySidebar.promoCode.open"
                  defaultMessage="Do you have coupon code?"
                />
              </a>
            </div>
    
    
        </div>
      );
    };

export default OfferPricingSummary;