import { Select } from "antd"
import { AiStrategySelector, GetAiStrategies } from "modules/panel/state/AISlice"
import { useEffect, useMemo } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams"

const AIStrategySelect = (props: any) => {

    const aiStrategies = useSelectorWithParams(AiStrategySelector);

    const options = useMemo(() => {

        if (!aiStrategies) return [];

        return aiStrategies.map((aiStrategy: any) => ({
            label: aiStrategy.name,
            value: aiStrategy.id
        }))

    }, [aiStrategies])

    useEffect(() => {
        if (!options.length) return;
        props.onChange(options[0].value)
        props.onBlur();
    }, [options]);

    if (!options || !options.length) {
        return (
            <Select 
                disabled={true}
            />
        )
    }

    return (
        <Select 
            {...props}
            options={options}
            defaultValue={options[0].value}
            value={props.value || options[0].value}
             />
    )

}

export default AIStrategySelect;