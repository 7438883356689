import React, { Component } from "react";
import OnboardingControls from "providers/onboarding/controls";
import SuccessPage from "_components/OnboardingSuccessPage";
import FormattedMessage from "components/common/FormattedMessage";

class WeWillContactUWithinDaysOnboarding extends Component {
    render() {
        return (
            <>
                <SuccessPage>
                    <p>
                        <FormattedMessage
                            id="panel.containers.onboarding.details.phone.thanks"
                            defaultMessage="We will contact you within 2 days."
                        />
                    </p>
                </SuccessPage>
                <OnboardingControls />
            </>

        );
    }
}

export default WeWillContactUWithinDaysOnboarding;
