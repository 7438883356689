import { createContext, useContext, useEffect, useState } from "react";
import { SubscriptionOffer } from "state/subscriptions/subscriptionPricingHelpers";
import usePromoCodeCheck from "./usePromoCodeCheck";
import Payment from "../Payment";
import PurchaseProjectSelect from "./PurchaseProjectSelect";
import PurchaseSubscriptionOfferSelect from "./PurchaseSubscriptionOfferSelect";
import PaymentMethodSelect from "../PaymentMenthodSelect";
import PurchaseConsent from "../PurchaseConsent";
import { callAPIProps } from "services/hooks/useAPI";
import { purchaseSubscription } from "state/subscriptions/subscriptionsEndpoints";
import FormattedMessage from "components/common/FormattedMessage";
import usePartnerCodeCheck from "./usePartnerCodeCheck";

export type SubscriptionPurchaseInitialOptions = Partial<{
    product: string;
    offerId: string;
    projectId: string;
    promoCode: string;
    partnerCode: string;
    showAll: boolean;
}>

type PurchaseSubscriptionProps = {
    initialPurchaseOptions: SubscriptionPurchaseInitialOptions;
    children?: React.ReactNode;
    onComplete?: (data: any) => void;
}


type PurchaseSubscriptionContextProps = {

    product: string;
    setProduct: (product: string) => void;

    offer: SubscriptionOffer | null;
    setOffer: (offer: SubscriptionOffer | null) => void;

    projectId: string;
    setProjectId: (projectId: string) => void;

    promoCodeCheck: ReturnType<typeof usePromoCodeCheck>;

    promoCode: string;
    setPromoCode: (promoCode: string) => void;

    isPromoCodeValid: boolean;
    setIsPromoCodeValid: (isPromoCodeValid: boolean) => void;

    partnerCode: string;
    setPartnerCode: (partnerCode: string) => void;

    consents: {
        checkOne: boolean;
        checkTwo: boolean;
    }

    setConsents: (consents: { checkOne: boolean, checkTwo: boolean }) => void;

    initialPurchaseOptions: {
        product: string;
        offerId: string;
        projectId: string;
        promoCode: string;
        partnerCode: string;
        showAll: boolean;
    }

    partnerCodeManager: ReturnType<typeof usePartnerCodeCheck>;


}

const PurchaseSubscriptionContext = createContext<PurchaseSubscriptionContextProps>({});
PurchaseSubscriptionContext.displayName = "PurchaseSubscriptionContext";
export const usePurchaseSubscription = () => useContext(PurchaseSubscriptionContext);

const PurchaseSubscription = (props: PurchaseSubscriptionProps) => {

    const [product, setProduct] = useState(props.initialPurchaseOptions.product);
    const [projectId, setProjectId] = useState(props.initialPurchaseOptions.projectId);
    const [offer, setOffer] = useState<SubscriptionOffer | null>();
    const [promoCode, setPromoCode] = useState(props.initialPurchaseOptions.promoCode);
    const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
    const [partnerCode, setPartnerCode] = useState(props.initialPurchaseOptions.partnerCode);

    const partnerCodeManager = usePartnerCodeCheck({
        partnerCode: partnerCode,
        setPartnerCode: setPartnerCode
    });

    const promoCodeCheck = usePromoCodeCheck({
        promoCode,
        setPromoCode,
        setIsPromoCodeValid,
        offer
    })
    const [consents, setConsents] = useState<PurchaseSubscriptionContextProps["consents"]>({ checkOne: false, checkTwo: false });

    useEffect(() => {
        if (props.initialPurchaseOptions.promoCode && offer) {
            promoCodeCheck.checkPromoCode(props.initialPurchaseOptions.promoCode);
        }
    }, [props.initialPurchaseOptions, offer]);

    const canPurchase = (() => {
        if (!offer || !projectId) return false;
        if (!consents.checkOne || !consents.checkTwo) return false;
        return true;
    })()

    const purchaseCall = (): callAPIProps => {

        const purchaseCall = purchaseSubscription({
            project_id: projectId,
            plan_id: offer?.id || 0,
            promo_code: promoCode || "",
            partner_code: partnerCode || "",
        }, projectId, product)

        return purchaseCall;
    }

    const handleSuccess = (data: any) => {
        props.onComplete && props.onComplete(data);
    }

    const value = {
        product,
        setProduct,
        offer,
        setOffer,
        projectId,
        setProjectId,
        promoCodeCheck,
        promoCode,
        setPromoCode,
        isPromoCodeValid,
        setIsPromoCodeValid,
        partnerCode,
        setPartnerCode,
        consents,
        setConsents,
        initialPurchaseOptions: props.initialPurchaseOptions,
        partnerCodeManager
    }

    return (
        <PurchaseSubscriptionContext.Provider value={value}>
            <Payment
                paymentCall={purchaseCall()}
                onComplete={handleSuccess}
                canPay={canPurchase}
                paymentButtonLabel={
                    <FormattedMessage
                        id="panel.components.containers.creditCard.buySubscription"
                        defaultMessage="Buy subscription"
                    />
                }
                hideButtons={!offer?.id}
            >
                {offer?.id ? (
                    <PurchaseProjectSelect
                        value={projectId}
                        onChange={setProjectId}
                    />) : null}
                <PurchaseSubscriptionOfferSelect
                    showAll={props.initialPurchaseOptions.showAll}
                    initialOfferId={props.initialPurchaseOptions.offerId}
                />
                {props.children}
                {offer?.id ? (
                    <>
                        <PaymentMethodSelect />
                        <PurchaseConsent />
                    </>
                ) : null}
            </Payment>
        </PurchaseSubscriptionContext.Provider>
    )



}

export default PurchaseSubscription