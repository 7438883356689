import FormattedMessage from "components/common/FormattedMessage";
import { UpdateIcon } from "modules/panel/components/ft-icons";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import StandardButton from "components/common/StandardButton";
import { BotUpdate } from "./form/BotUpdate";
import { RemoveBotBtn } from "./RemoveBotBtn";
import { faTrashCircle } from "@fortawesome/pro-light-svg-icons"
import AwesomeIcon from "components/common/AwesomeIcon";
import { ReactNode } from "react";
import ButtonWithIcon from "modules/smart/components/list-item/base-list-item/ButtonWithIcon";

type Props = {
  botId: number
  btn?: ReactNode
}

export const UpdateBotBtn = (props: Props) => {

  const [drawerRef, open, close] = useStandardDrawer();

  const btnWithOnClick = (
    <StandardButton type="ghost"
      onClick={open}>
      {props.btn}
    </StandardButton>
  );

  return (
    <>
      {props.btn === undefined ? (
        <ButtonWithIcon
          icon={<UpdateIcon />}
          onClick={open}>
          <FormattedMessage
            id="bot.components.createBot.heading"
            defaultMessage="Edit Message Bot"
          />
        </ButtonWithIcon>
      ) : (
        btnWithOnClick
      )}

      <StandardDrawer
        ref={drawerRef}
        width={"1300px"}
        layout="standard"
        mask={true}
        title={
          <FormattedMessage
            id="bot.components.createBot.heading"
            defaultMessage="Edit Message Bot"
          />
        }
        zIndex={1000}
        onClose={close}
        footer={
          <div>
            <StandardButton >
              <RemoveBotBtn botId={props.botId} close={close}>
                <AwesomeIcon icon={faTrashCircle}/>
              </RemoveBotBtn>
            </StandardButton>
          </div>
        }
      >
        <BotUpdate botId={props.botId} />

      </StandardDrawer>

    </>
  )
}

