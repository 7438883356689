import { SmsApiIcon, FreshMailIcon } from "modules/panel/components/ft-icons";
import FormattedMessage from "components/common/FormattedMessage";
import { useSelector } from "react-redux";
import { Avatar } from "antd";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentProjectSelector } from "state/projects/projectSelectors";

export type IntegrationTypes = "sms-api" | "fresh-mail" | undefined;

type Props = {
  type: IntegrationTypes;
};

const Integration = ({ type }: Props) => {
  const project = useSelectorWithParams(currentProjectSelector);

  return (
    <>
      {!type && (
        <>
          <Avatar shape="circle" src={project.image} />
          <span className="integration-type">
            <FormattedMessage
              id="apps.bot.components.integrationType.simpleReply"
              defaultMessage="Simple reply"
            />
          </span>
        </>
      )}
      {type === "sms-api" && (
        <>
          <SmsApiIcon /> <span className="integration-type">SMS API</span>
        </>
      )}
      {type === "fresh-mail" && (
        <>
          <FreshMailIcon /> <span className="integration-type">Freshmail</span>
        </>
      )}
    </>
  );
};

export default Integration;
