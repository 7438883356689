import React, { useEffect, useMemo, useRef, useState } from "react";
import useAPI, { APIErrorDisplay, callAPIProps } from "services/hooks/useAPI";
import ProviderIntegrationField from "./ProviderIntegrationField";

import { useSelector } from "react-redux";
import { Space } from "antd";
import { ProviderConfig } from "modules/panel/config/ProviderConfig";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import StandardButton from "components/common/StandardButton";
import FormattedMessage from "components/common/FormattedMessage";
import _ from "lodash";
import { currentSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { currentProductSelector } from "services/store/scopeSlice";
import { EditProject, EditProjectRecursionFix } from "state/projects/projectEndpoints";

type provider = "facebook" | "google";

export type ProviderIntegrationProps = {
  provider: provider;
  onValuesChanged?: (values: any) => void;
};

export type ProviderIntegrationWrapperProps = {
  provider: provider;
  children?: (
    isFilledCorrectly: boolean,
    onSubmit: () => void,
    values: any
  ) => React.ReactNode;
};

const FACEBOOK_AD_ACCOUNTS = "facebookAdAccounts";
const FACEBOOK_PIXELS = "facebookPixels";
const FACEBOOK_PAGES = "facebookPages";
const FACEBOOK_INSTAGRAM = "facebookInstagramAccounts";

const GOOGLE_AD_ACCOUNTS = "googleAdAccounts";
const GOOGLE_ANALYTICS = "googleAnalytics";
const GOOGLE_MY_BUSINESSES = "googleMyBusinesses";
const GOOGLE_SEARCH_CONSOLES = "googleSearchConsoles";

const PermissionToFieldName: any = {
  analytics: GOOGLE_ANALYTICS,
  gAdAccount: GOOGLE_AD_ACCOUNTS,
  myBusiness: GOOGLE_MY_BUSINESSES,
  searchConsole: GOOGLE_SEARCH_CONSOLES,
  fbAdAccount: FACEBOOK_AD_ACCOUNTS,
  fanpage: FACEBOOK_PAGES,
  pixel: FACEBOOK_PIXELS,
  instagram: FACEBOOK_INSTAGRAM,
};

const finalizeData = (data: any) => {
  const out: any = {};
  if (data.facebookAdAccounts)
    out.service_facebook_ads_account_id = data.facebookAdAccounts;
  if (data.facebookPixels)
    out.service_facebook_ads_account_pixels_id = data.facebookPixels;
  if (data.facebookPages) out.service_facebook_page_id = data.facebookPages;
  if (data.facebookAdAccounts)
    out.service_facebook_ads_account_id = data.facebookAdAccounts;
  if (data.facebookInstagramAccounts)
    out.service_facebook_ads_account_instagrams_id =
      data.facebookInstagramAccounts;

  if (data.googleMyBusinesses)
    out.service_google_my_business_id = data.googleMyBusinesses;
  if (data.googleAdAccounts) out.service_google_ads_id = data.googleAdAccounts;
  if (data.googleAnalytics)
    out.service_google_analytics_id = data.googleAnalytics;
  if (data.googleSearchConsoles)
    out.service_google_search_console_id = data.googleSearchConsoles;

  //remove properties out of "out" that equal "autoGenerate"
  Object.keys(out).forEach((key) => {
    if (out[key] === "autoGenerate") out[key] = -1;
  });

  return out;
};

// PROVIDER INTEGRATION WRAPPER
// Selecting pages, adaccounts, etc.
const ProviderIntegration = (props: ProviderIntegrationProps) => {
  const providerConfig = ProviderConfig.get(props.provider);
  const permissions = useSelectorWithParams(currentSubscriptionPermissionsSelector);
  const [values, setValues] = useState<any>({});

  useEffect(() => {
    props.onValuesChanged && props.onValuesChanged(values);
  }, [values]);

  const allowedFields = useMemo(() => {
    if (!permissions) return [];
    return (permissions as any).providers[props.provider];
  }, [permissions]);

  const {
    call: refreshRequest,
    data: refreshResult,
    error: refreshError,
  } = useAPI(
    {
      url: ({ getApiUrl }) => getApiUrl("services/refresh", 3),
      method: "POST",
      body: {
        services: Array.from((providerConfig as any).fields.keys()),
      },
    },
    true
  );

  const handleChange = (field: string, value: string | undefined) => {
    setValues((v: any) => ({ ...v, [field]: value }));
  };

  const getField = (filed: string) => (
    <ProviderIntegrationField
      values={values}
      provider={props.provider}
      field={filed}
      onChange={handleChange}
    />
  );

  const fields = () => {
    if (refreshError || !refreshResult || !allowedFields) return;
    const fields: React.ReactNode[] = [];

    if (props.provider === "google") {
      if (allowedFields.includes("myBusiness"))
        fields.push(getField(GOOGLE_MY_BUSINESSES));
      if (allowedFields.includes("gAdAccount"))
        fields.push(getField(GOOGLE_AD_ACCOUNTS));
      if (allowedFields.includes("analytics"))
        fields.push(getField(GOOGLE_ANALYTICS));
      if (allowedFields.includes("searchConsole"))
        fields.push(getField(GOOGLE_SEARCH_CONSOLES));
    }
    if (props.provider === "facebook") {
      if (allowedFields.includes("fanpage"))
        fields.push(getField(FACEBOOK_PAGES));
      if (allowedFields.includes("fbAdAccount"))
        fields.push(getField(FACEBOOK_AD_ACCOUNTS));
      if (allowedFields.includes("instagram"))
        fields.push(getField(FACEBOOK_INSTAGRAM));
      if (allowedFields.includes("pixel"))
        fields.push(getField(FACEBOOK_PIXELS));
    }

    return fields;
  };

  if (refreshError)
    return <div>There was an error while getting your data.</div>;

  if (!refreshResult || !fields) return <div>Loading...</div>;

  return (
    <div className="provider-integration">
      <Space direction="vertical" style={{ width: "100%" }}>
        {fields()}
      </Space>
      <APIErrorDisplay error={refreshError} />

      {/* <ConnectedOnboardingControls nextDisabled = {checkDisabled()} clickOverride={submit}/> */}
    </div>
  );
};

// PROVIDER INTEGRATION WRAPPER
export const ProviderIntegrationWrapper = (
  props: ProviderIntegrationWrapperProps
) => {
  const [values, setValues] = useState<any>({});
  const providerConfig = ProviderConfig.get(props.provider);
  const product = useSelectorWithParams([currentProductSelector]);
  const permissions = useSelector(currentSubscriptionPermissionsSelector);

  const allowedFields = useMemo(() => {
    if (!permissions) return [];
    return (permissions as any).providers[props.provider];
  }, [permissions]);

  const updateProjectCall = useAPI(EditProjectRecursionFix);

  const submit = async () => {
    if (!values) return;
    if (Object.keys(values).length === 0) return;
    const data = finalizeData(values);
    if (!data) return;
    console.log("data", data);
    const res = await updateProjectCall.call({
        body: data
    })

    if(res.status === 200 && res.data?.id !== undefined){
        return true
    }else{
        return false
    }


  };
  const checkFilledByPermission = (permission: string) => {
    const fieldName = PermissionToFieldName[permission];
    if (values[fieldName]) return true;
    if (
      providerConfig &&
      (providerConfig.fields as any).get(fieldName)?.allowEmpty
    ) {
      return true;
    }
    return false;
  };

  const checkByPermissions = () =>
    allowedFields &&
    allowedFields.every((field: any) => {
      return checkFilledByPermission(field);
    });

  const checkDisabled = () => {
    if (!values) return true;
    const filled = checkByPermissions();

    if (props.provider === "google") {
      if (product === "forsant" || product === "Forsant") {
        if (!values.googleMyBusinesses) return true;
        if (!values.googleAnalytics) return true;
        return false;
      }

      if (product === "SmartCall") {
        return false;
      }
    }

    return !filled;
  };

  return (
    <>
      <ProviderIntegration
        provider={props.provider as any}
        onValuesChanged={setValues}
      />
      <APIErrorDisplay error={updateProjectCall.error} />
      {props.children ? props.children(checkDisabled(), submit, values) : null}
    </>
  );
};

export const ProviderIntegrationOnboarding = (props: { provider: string }) => {
  return (
    <>
      <ProviderIntegrationWrapper provider={props.provider as any}>
        {(isFilledCorrectly: boolean, submit: () => void) => {
          return (
            <ConnectedOnboardingControls
              nextDisabled={isFilledCorrectly}
              clickOverride={submit}
            />
          );
        }}
      </ProviderIntegrationWrapper>
    </>
  );
};

export const ProviderIntegrationUpdate = (props: { provider: string }) => {
  return (
    <>
      <ProviderIntegrationWrapper provider={props.provider as any}>
        {(isFilledCorrectly: boolean, submit: () => void, values) => {
          return (
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <StandardButton
                type="primary"
                disabled={isFilledCorrectly}
                onClick={submit}
                style={{ marginTop: "20px" }}
              >
                <FormattedMessage id="common.form.save" defaultMessage="Save" />
              </StandardButton>
            </div>
          );
        }}
      </ProviderIntegrationWrapper>
    </>
  );
};

//<ConnectedOnboardingControls nextDisabled = {checkDisabled()} clickOverride={submit}/>
export const OnboardingProviderIntegrationGoogle = () => (
  <ProviderIntegrationOnboarding provider="google" />
);
export const OnboardingProviderIntegrationFacebook = () => (
  <ProviderIntegrationOnboarding provider="facebook" />
);

export const ProviderIntegrationGoogle = () => (
  <ProviderIntegrationUpdate provider="google" />
);
export const ProviderIntegrationFacebook = () => (
  <ProviderIntegrationUpdate provider="facebook" />
);
