
import { Col, Input, Row } from "antd";
import { rowLayout } from "services/helpers/forms-helpers";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import DateInput from "_components/forms/date-input";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import NipInput from "_components/forms/masked-inputs/nip-input";
import WebsiteInput from "_components/forms/website-input";
import PhoneNumberInputWithCountrySelect from "_components/forms/PhoneNumberInputWithCountrySelect";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import AddressWithMap from "components/map/AddressWithMap";
import { useEffect, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { UpdateWebsiteTemaplate, WebsiteSelector } from "../reducers/websiteSlice";
import OpeningHours from "./OpeningHours";

type Props = {
    companyData?: {}
}


export default function CompanyForm(props: Props) {
    const [country, setCountry] = useState();
    const settings = useSelectorWithParams(WebsiteSelector)

    useEffect(() => {
        if(settings) setCountry(settings.country)
    }, [])

    return (
        <>
            <RelaxMultiform
                initialValuesSelector={WebsiteSelector}
                callAPI={UpdateWebsiteTemaplate}
                finalizeSubmit={(value: any) => {
                    if (value.country) {
                        setCountry(value.country)
                    }
                    return value
                }}
            >

                <Row {...rowLayout}>
                    <Col xs={24} md={12}>
                        <RelaxSingleField
                            name="company_name"
                            label={
                                <FormattedMessage
                                    id="apps.websites.forms.companyData.companyName"
                                    defaultMessage="Company name"
                                />
                            }
                        >
                            <Input />
                        </RelaxSingleField>
                    </Col>
                    <Col xs={24} md={12}>
                        <Row {...rowLayout}>
                            <Col xs={24} md={12}>
                                <RelaxSingleField
                                    name="company_short_name"
                                    label={
                                        <FormattedMessage
                                            id="apps.websites.forms.companyData.shortCompanyName"
                                            defaultMessage="Short company name"
                                        />
                                    }
                                >
                                    <Input />
                                </RelaxSingleField>
                            </Col>
                            <Col xs={24} md={12}>
                                <RelaxSingleField
                                    name="company_begin_date"
                                    label={
                                        <FormattedMessage
                                            id="apps.websites.forms.companyData.openingDate"
                                            defaultMessage="Opening date"
                                        />
                                    }
                                >
                                    <DateInput
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                </RelaxSingleField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <AddressWithMap country={country} />
                <Row {...rowLayout}>
                    <Col xs={24} md={12}>
                        <RelaxSingleField
                            name="nip"
                            required={true}
                            label={
                                <FormattedMessage
                                    id="apps.websites.forms.companyData.nip"
                                    defaultMessage="VAT number"
                                />
                            }

                        >
                            <NipInput />
                        </RelaxSingleField>
                    </Col>
                    <Col xs={24} md={12}>
                        <RelaxSingleField
                            name="regon"
                            label={
                                <FormattedMessage
                                    id="apps.websites.forms.companyData.regon"
                                    defaultMessage="REGON"
                                />
                            }
                        >
                            <Input />
                        </RelaxSingleField>
                    </Col>
                </Row>


                <Row {...rowLayout}>
                    <Col xs={24} md={12}>
                        <RelaxSingleField
                            name="www"
                            label={
                                <FormattedMessage
                                    id="apps.websites.forms.companyData.www"
                                    defaultMessage="Website"
                                />
                            }
                        >
                            <WebsiteInput />
                        </RelaxSingleField>
                    </Col>
                    <Col xs={24} md={12}>
                        <RelaxSingleField
                            name="email"
                            required={true}
                            label={
                                <FormattedMessage
                                    id="apps.websites.forms.companyData.email"
                                    defaultMessage="Email"
                                />
                            }
                        >
                            <Input />
                        </RelaxSingleField>
                    </Col>
                </Row>
                <Row {...rowLayout}>
                    <Col xs={24} md={12} lg={24}>
                        <RelaxSingleField
                            name="phone"
                            required={true}
                            label={
                                <FormattedMessage
                                    id="apps.websites.forms.companyData.phoneNumber"
                                    defaultMessage="Workshop phone number"
                                />
                            }
                        >
                            <PhoneNumberInputWithCountrySelect />
                        </RelaxSingleField>
                    </Col>
                </Row>
                <div className="hoursWrapper">
                    <RelaxSingleField
                        name="working_hours"
                        finalizeSubmit={(values: any) => {
                            const restDays = settings.working_hours.filter((day: any) => (day.day === "saturday" || day.day === 'sunday'))
                            values.working_hours = values.working_hours.concat(restDays)
                            return values;
                        }}
                        label={
                            <FormattedMessage
                                id="apps.websites.forms.companyData.openingHours.workweek"
                                defaultMessage="Opening hours (Monday - Friday)"
                            />
                        }
                    >
                        <OpeningHours type="workweek" />
                    </RelaxSingleField>
                </div>
                <div className="hoursWrapper">
                    <RelaxSingleField
                        name="working_hours"
                        finalizeSubmit={(values: any) => {
                            const restDays = settings.working_hours.filter((day: any) => (day.day !== "saturday" && day.day !== 'sunday'))
                            values.working_hours = values.working_hours.concat(restDays)
                            return values;
                        }}
                        label={
                            <FormattedMessage
                                id="apps.websites.forms.companyData.openingHours.weekend"
                                defaultMessage="Opening hours (Saturday - Sunday)"
                            />
                        }
                    >
                        <OpeningHours type="weekend" />
                    </RelaxSingleField>
                </div>
            </RelaxMultiform>
        </>
    )

}
