
import { BotPreview } from "modules/bot/components/posts-list-item/options/form/preview/BotPreview";
import './BotForm.less'
import { BotEditor } from "./BotEditor";

type Props = {
  botBasedOn: string,
  setBotBasedOn: (data: string) => void,
  integration:string
  setIntegration: (data: string) => void,
  createdBotData:any
}

export const BotCreate = (props: Props) => {

  return (
    <>
      <div className="bot-form">
       
          <BotEditor
            integration={props.integration}
            setIntegration={props.setIntegration}
            botBasedOn={props.botBasedOn}
            setBotBasedOn={props.setBotBasedOn}
            hideStatus={true}
            actionType="create"
          />
          {window.innerWidth >= 800 ? (
            <BotPreview integration={props.integration} bot={props.createdBotData}/>
          ) : (
            ('')
          )}
        
      </div>
    </>
  );
};
