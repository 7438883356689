import {createSlice, createSelector} from '@reduxjs/toolkit';
import { LOAD_USER_SUCCESS } from 'modules/panel/actions/user/login';
import { callAPIProps } from 'services/hooks/useAPI';
import { currentProjectIdSelector } from 'services/store/scopeSlice';


export type WebsiteState = any;

const initialState: WebsiteState = {
}


export const websiteSlice = createSlice({
    name: "website",
    initialState, 
    reducers: {
        create: (state, action) => {
            state[action.payload.projectId] = action.payload.data;
        },
        get: (state, action) => {
            state[action.payload.projectId] = action.payload.data;
        },
        update: (state, action) => {
            state[action.payload.projectId] = action.payload.data.attributes;      
        }
    },
extraReducers: {
    [LOAD_USER_SUCCESS]: (state, action) => {
        if (action?.payload?.websites) {
            for (const [key, value] of Object.entries(action.payload.websites)) {
                state[key] = value
            }
        } else if (action?.payload?.projects) {
            for (const [key, value] of Object.entries(action.payload.projects)) {
                if (value.website) {
                    state[key] = value?.website
                    state[key].project_id = value?.id
                    delete state[key].project
                }
            }
        }
    },
}
})


export const GetWebsite = ():callAPIProps => ({
    url: ({ getApiUrl, language, projectId}: any) => getApiUrl(`projects/${projectId}/website/`,2),
    method: "GET",
    successDispatch: websiteSlice.actions.get,
})

export const UpdateWebsite: callAPIProps = {
    url: ({ getApiUrl, language, projectId}: any) => getApiUrl(`projects/${projectId}/website/`,2),
    method: "PUT",
    successDispatch: websiteSlice.actions.get
}


export const UpdateWebsiteTemaplate: callAPIProps = {
    url: ({ getApiUrl, language, projectId}: any) => getApiUrl(`projects/${projectId}/website/`,2),
    method: "PUT",
    successDispatch: websiteSlice.actions.get
}


export const WebsiteSelector = createSelector([
    (state:any) => state?.siteGenerator?.websites,
    currentProjectIdSelector
], 
    (state:any, projectId:any) => state?.[projectId]
)

export const WebsiteIndustrySelector = createSelector([
    WebsiteSelector
], 
    (state:any) => state?.industry
);

export const WebsiteCarserviceCategoriesSelector = createSelector([
    WebsiteSelector
], 
    (state:any) => state?.categories
);


export const userHasFilledCarserviceCategoriesSelector = createSelector([
    WebsiteCarserviceCategoriesSelector
], 
    (categories:any) => categories?.length > 0
);

export const WebsiteCarserviceBrandsSelector = createSelector([
    WebsiteSelector
], 
    (state:any) => state?.brands
);

export const userHasFilledBrandsSelector = createSelector([
    WebsiteCarserviceBrandsSelector
], 
    (brands:any) => brands?.length > 0
);

export const WebsiteCarserviceManufacturersSelector = createSelector([
    WebsiteSelector
], 
    (state:any) => state?.manufacturers
);

export const userHasFilledManufacturersSelector = createSelector([
    WebsiteCarserviceManufacturersSelector
], 
    (manufacturers:any) => manufacturers?.length > 0
);

export const WebsiteCarserviceTiresSelector = createSelector([
    WebsiteCarserviceManufacturersSelector
], 
    (manufacturers:any) => manufacturers?.tires
);

export const userHasFilledTiresSelector = createSelector([
    WebsiteCarserviceTiresSelector
], 
    (tires:any) => tires?.length > 0
);

export const UserHasWebsitesWithIndustrySelector = createSelector([
    WebsiteSelector
],
    (website:any) => {
        if (!website) return false;
        if (!website.industry) return false;
        return true;
    }
)

export const ProjectWebsiteTemplatesSelector = createSelector([
    (state:any) => state?.siteGenerator.templates,
    currentProjectIdSelector
],
    (state:any, projectId:any) => state && state[projectId]
)

export const UserHasFilledWebsiteTheme = createSelector([
    WebsiteSelector
],
    (website:any) => {
        if (!website) return false;
        if (website.template_id || website.template_number) return true;
        return false;
    }
)

export const UserHasFilledCompanyDataSelector = createSelector(
    WebsiteSelector,
    (webiste) =>
      webiste
        ? webiste.company_name !== null &&
          webiste.address !== null &&
          webiste.zip_code !== null &&
          webiste.city !== null &&
          webiste.latitude !== null &&
          webiste.longitude !== null &&
          webiste.country !== null &&
          webiste.nip !== null &&
          webiste.phone !== null &&
          webiste.email !== null
        : false
  );
  

export const WebsiteThemeConfigSelector = createSelector([
    WebsiteSelector
], (website:any) => {

    if (!website) return null;

    const {
        color_alternative, 
        color_background, 
        color_font, 
        color_main, 
        color_theme, 
        color_theme_id,
        font_header, 
        font_text, 
        slug, 
        template_number, 
        template_id,
    } = website;

    return {
        color_alternative,
        color_background,
        color_font,
        color_main,
        color_theme,
        color_theme_id,
        font_header, 
        font_text, 
        slug, 
        template_number, 
        template_id,
    }

})

export const UserWebsiteUrl = createSelector(WebsiteSelector, (website) => {
    if (!website) return "";
  
    let hasDomain = website.domain_active && website.domain
    return hasDomain
      ? `${website.domain_ssl ? "https://" : "http://"}${website.domain}`
      : `https://${website.slug}.${import.meta.env.VITE_WEBSITE_SITE_DOMAIN}`;
  });

export const UserWebsiteLabel = createSelector(WebsiteSelector, (website) => {
    if (!website) return "";
    let hasDomain = website.domain_active && website.domain;
    return hasDomain
      ? `${website.domain_ssl ? "https://" : "http://"}${website.domain}`
      : website.slug;
  });

export const DomainSelector = createSelector(WebsiteSelector, (website) => {
    if (!website) return "";
    return website.domain_ssl
      ? "https://"
      : "http://" + website.domain;
  });
  

export default websiteSlice;