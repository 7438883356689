
export const events = [
    {
        "id": "1",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant",
        "date": "20.02.2024",
        "timeStart": "12:00",
        "timeEnd": "16:00",
        "link": "",
        "status": "ukończone",
        "image": "/images/calendarEvents/CEO.png"
    },
    {
        "id": "2",
        "name": "jak odebrać 200.000 euro dotacji na stworzenie agencji reklamowej lub działu Ads w Twojej firmie",
        "date": "23.02.2024",
        "timeStart": "20:00",
        "timeEnd": "20:40",
        "link": "https://webinar.freshmail.pl/270-396-298",
        "image": "/images/calendarEvents/2.png"
    },
    {
        "id": "3",
        "name": "Zaproszenie do zapisów na FastTony AI + Open AI Sora do generowania rolek i video na podstawie tekstów + specjalna oferta na FastTony Post",
        "date": "25.02.2024",
        "timeStart": "",
        "timeEnd": "",
        "link": "https://webinar.freshmail.pl/966-994-831",
        "image": "/images/calendarEvents/3.png"
    },
    {
        "id": "4",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant",
        "date": "27.02.2024",
        "timeStart": "12:00",
        "timeEnd": "16:00",
        "link": "https://webinar.freshmail.pl/478-457-298",
        "image": "/images/calendarEvents/CEO.png"
    },
    {
        "id": "5",
        "name": "podsumowanie zmian w FastTony",
        "date": "28.02.2024",
        "timeStart": "20:00",
        "timeEnd": "21:30",
        "link": "https://webinar.freshmail.pl/468-653-428",
        "image": "/images/calendarEvents/5.png"
    },
    {
        "id": "6",
        "name": "Windykacja - system który zrobi to za ciebie bez abonamentu zapisy - przestrzedaż",
        "date": "01.03.2024",
        "timeStart": "20:00",
        "timeEnd": "21:30",
        "link": "https://webinar.freshmail.pl/832-030-528",
        "image": "/images/calendarEvents/6.png"
    },
    {
        "id": "7",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant",
        "date": "05.03.2024",
        "timeStart": "12:00",
        "timeEnd": "16:00",
        "link": "https://webinar.freshmail.pl/663-944-479",
        "image": "/images/calendarEvents/CEO.png"
    },
    {
        "id": "8",
        "name": "COMBO czyli jak zrobić Google Ads + Facebook Ads + SMS z follow up + zbieranie opinii w Google",
        "date": "07.03.2024",
        "timeStart": "20:00",
        "timeEnd": "22:00",
        "link": "https://webinar.freshmail.pl/171-896-972",
        "image": "/images/calendarEvents/8.png"
    },
    {
        "id": "9",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant",
        "date": "12.03.2024",
        "timeStart": "12:00",
        "timeEnd": "16:00",
        "link": "https://webinar.freshmail.pl/743-859-815",
        "image": "/images/calendarEvents/CEO.png"
    },
    {
        "id": "10",
        "name": "Prelekcja na konferencji AI Rzeszów - spotkajmy się na zapleczu i porozmawiajmy o biznesach",
        "date": "14.03.2024",
        "timeStart": "",
        "timeEnd": "",
        "link": "",
        "image": "/images/calendarEvents/10.png"
    },
    {
        "id": "101",
        "name": "Prelekcja na konferencji AI Rzeszów - spotkajmy się na zapleczu i porozmawiajmy o biznesach",
        "date": "15.03.2024",
        "timeStart": "",
        "timeEnd": "",
        "link": "",
        "image": "/images/calendarEvents/10.png"
    },
    {
        "id": "11",
        "name": "SALMED Poznań wystąpienie Power Speech - AI to rule them All",
        "date": "19.03.2024",
        "timeStart": "10:30",
        "timeEnd": "10:45",
        "link": "",
        "image": "/images/calendarEvents/11-12.png"
    },
    {
        "id": "12",
        "name": "SALMED Poznań wystąpienie panel dyskusyjny - AI, Robotyka, Automatyzacja w Medycynie",
        "date": "19.03.2024",
        "timeStart": "14:45",
        "timeEnd": "15:00",
        "link": "",
        "image": "/images/calendarEvents/11-12.png"
    },
    {
        "id": "13",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant - JA",
        "date":"19.03.2024",
        "timeStart": "12:00",
        "timeEnd": "14:00",
        "link": "https://webinar.freshmail.pl/775-937-389"
    },
    {
        "id": "14",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant - ZASTĘPSTWO",
        "date": "19.03.2024",
        "timeStart": "12:00",
        "timeEnd": "14:00",
        "link": "https://webinar.freshmail.pl/775-937-389",
        "image": "/images/calendarEvents/CEO.png"
    },
    {
        "id": "15",
        "name": "Call App czyli jak poprzez SMS zwiększyć sprzedaż w każdym lokalnym biznesie usługowym",
        "date": "22.03.2024",
        "timeStart": "20:00",
        "timeEnd": "21:30",
        "link": "https://webinar.freshmail.pl/377-477-460",
        "image": "/images/calendarEvents/15.png"
    },
    {
        "id": "16",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant",
        "date": "26.03.2024",
        "timeStart": "12:00",
        "timeEnd": "16:00",
        "link": "https://webinar.freshmail.pl/672-412-775",
        "image": "/images/calendarEvents/CEO.png"
    },
    {
        "id": "17",
        "name": "I-sza konferencja marketingowa w METAVERSE",
        "date": "1.04.2024",
        "timeStart": "18:00",
        "timeEnd": "22:00",
        "link": "https://webinar.freshmail.pl/770-657-467"
    },
    {
        "id": "18",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant",
        "date": "2.04.2024",
        "timeStart": "12:00",
        "timeEnd": "16:00",
        "link": "https://webinar.freshmail.pl/386-954-687",
        "image": "/images/calendarEvents/18.png"
    },
    {
        "id": "19",
        "name": "Prelekcja na konferencji w Warszawie - KURS na MILION",
        "date": "05.04.2024",
        "timeStart": "",
        "timeEnd": "",
        "link": ""
    },
    {
        "id": "20",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant",
        "date": "9.04.2024",
        "timeStart": "12:00",
        "timeEnd": "16:00",
        "link": "https://webinar.freshmail.pl/350-944-177",
        "image": "/images/calendarEvents/20.png"
    },
    {
        "id": "21",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant",
        "date": "16.04.2024",
        "timeStart": "12:00",
        "timeEnd": "16:00",
        "link": "https://webinar.freshmail.pl/512-334-175",
        "image": "/images/calendarEvents/CEO.png"
    },
    {
        "id": "22",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant",
        "date": "23.04.2024",
        "timeStart": "12:00",
        "timeEnd": "16:00",
        "link": "https://webinar.freshmail.pl/292-539-394",
        "image": "/images/calendarEvents/CEO.png"
    },
    {
        "id": "23",
        "name": "cykliczne spotkanie LIVE z Danielem Kędzierskim CEO FastTony & Forsant",
        "date": "30.04.2024",
        "timeStart": "12:00",
        "timeEnd": "16:00",
        "link": "https://webinar.freshmail.pl/823-091-518",
        "image": "/images/calendarEvents/CEO.png"
    }
]

