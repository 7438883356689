import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
//import "draft-js/dist/Draft.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg';
import { toolbarMinmal } from "./toolbarPresets";
import "./textEditor.less";

export type TextEditorProps = {
    value?: any, 
    updatedValue?: any,
    onChange?: any, 
    showRaw?:boolean,
    toolbar?: any
}

export default function TextEditor({value="", ...props}:TextEditorProps) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
    const [isLoaded, setIsLoaded] = useState(false);
  

    const rawDisplay = (<textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
    />)

    useEffect(() => {

        if (!!value && !isLoaded) {

            const blocksFromHtml = htmlToDraft(value);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const newEditorState = EditorState.createWithContent(contentState);
    
            setEditorState(newEditorState);
            setIsLoaded(true);
        }
    }, [value])

    
    useEffect(() => {

      if (props.updatedValue !== undefined) {

          const blocksFromHtml = htmlToDraft(props.updatedValue);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          const newEditorState = EditorState.createWithContent(contentState);
  
          setEditorState(newEditorState);
          setIsLoaded(true);
      }
  }, [props.updatedValue])

    const handleEditorChange = (newEditorState:any) => {
        setEditorState(newEditorState);
    }

    const handleContentChange = (newContentState:any) => {
        const updatedValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if (props.onChange) props.onChange(updatedValue);
    }

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        onContentStateChange={handleContentChange}
        wrapperClassName="textEditor-wrapper"
        editorClassName="textEditor-editor"
        toolbar={props.toolbar || toolbarMinmal}
        wrapperStyle={{height: "600px", paddingBottom: "50px"}}
      />
        {props.showRaw && rawDisplay}
    </div>
  );
}