import { ReactNode } from "react";
import { BaseCreationType, CreationSubtype, CreationType } from "./CreationConfig";
import { EditorAOA, EditorLeadAd } from "./editors/EditorAOAAndLead";
import EditorCarouselPostWithStages from "./editors/EditorCarousel";
import EditorNormalPost from "./editors/EditorNormalPost";
import FormattedMessage from "components/common/FormattedMessage";
import { Creation } from "./creationsSlice";
import EditorInstagramPost from "./editors/EditorInstagramPost";
import EditorCarousel from "./editors/EditorCarousel";
import EditorInstagramCarousel from "./editors/EditorInstagramCarousel";
import EditorInstagramAd from "./editors/EditorInstagramAd";
import EditorInstagramAoA from "./editors/EditorInstagramAOA";
import EditorInstagramStories from "./editors/EditorInstagramStories";

export type EditorConfig = {
    name: string;
    editor: ReactNode;
    editorTitle: ReactNode;
    validation: (creation: Creation) => boolean;
}

const EditorConfigByType: Record<BaseCreationType, Record<CreationSubtype, EditorConfig>> = {
    "post": {
        "image": {
            name: "image",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.image"
                    defaultMessage="Image Post" />,
            editor: <EditorNormalPost />,
            validation: (creation: Creation) => true
        },
        "instagram-image": {
            name: "instagram-image",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramImage"
                    defaultMessage="Instagram Image Post" />,
            editor: <EditorInstagramPost />,
            validation: (creation: Creation) => true
        },
        "video": {
            name: "video",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.video"
                    defaultMessage="Video Post" />,
            editor: <EditorNormalPost />,
            validation: (creation: Creation) => true
        },
        "instagram-video": {
            name: "instagram-video",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramVideo"
                    defaultMessage="Instagram Video Post" />,
            editor: <EditorInstagramPost />,
            validation: (creation: Creation) => true
        },
        "instagram-stories": {
            name: "instagram-stories",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramStory"
                    defaultMessage="Instagram Story" />,
            editor: <EditorInstagramStories />,
            validation: (creation: Creation) => true
        },
        "link": {
            name: "link",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.link"
                    defaultMessage="Link Post" />,
            editor: <EditorNormalPost />,
            validation: (creation: Creation) => true
        },
        "messenger": {
            name: "messenger",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.messanger"
                    defaultMessage="Messenger Post" />,
            editor: <EditorNormalPost />,
            validation: (creation: Creation) => true
        },
        "carousel": {
            name: "carousel",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.carousel"
                    defaultMessage="Carousel Post" />,
            editor: <EditorCarouselPostWithStages />,
            validation: (creation: Creation) => true
        },
        "instagram-carousel": {
            name: "instagram-carousel",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramCarousel"
                    defaultMessage="Instagram Carousel Post" />,
            editor: <EditorInstagramCarousel />,
            validation: (creation: Creation) => true
        },
        "instagram-link-social": {
            name: "instagram-link-social",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramLinkSocial"
                    defaultMessage="Instagram Social Ad" />,
            editor: <EditorInstagramAd />,
            validation: (creation: Creation) => true
        },
        "instagram-link-website": {
            name: "instgram-link-website",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramLinkWebsite"
                    defaultMessage="Instagram Website Ad" />,
            editor: <EditorInstagramAd />,
            validation: (creation: Creation) => true
        },
        "instagram-link-call": {
            name: "instagram-link-call",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramLinkCall"
                    defaultMessage="Instagram Call Ad" />,
            editor: <EditorInstagramAd />,
            validation: (creation: Creation) => true
        },
        "instagram-link-message": {
            name: "instagram-link-message",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramLinkMessage"
                    defaultMessage="Instagram Message Ad" />,
            editor: <EditorInstagramAd />,
            validation: (creation: Creation) => true
        },
    },
    "aoa": {
        "aoa": {
            name: "aoa",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.aoa."
                    defaultMessage="AOA" />,
            editor: <EditorAOA />,
            validation: (creation: Creation) => true
        },
        "event_aoa": {
            name: "event_aoa",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.aoaEvent"
                    defaultMessage="Event AOA" />,
            editor: <EditorAOA />,
            validation: (creation: Creation) => true
        },
        "w2l_aoa": {
            name: "w2l_aoa",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.w2l"
                    defaultMessage="Web to Learn - Traffic" />,
            editor: <EditorAOA />,
            validation: (creation: Creation) => true
        },
        "w2l_event": {
            name: "w2l_event",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.w2lEvent"
                    defaultMessage="Web to Learn - Conversion" />,
            editor: <EditorAOA />,
            validation: (creation: Creation) => true
        },
        "instagram_aoa": {
            name: "instagram_aoa",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramAOA"
                    defaultMessage="Instagram AOA" />,
            editor: <EditorInstagramAoA />,
            validation: (creation: Creation) => true
        },
        "instagram_event_aoa": {
            name: "instagram_event_aoa",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramEventAOA"
                    defaultMessage="Instagram Event AOA" />,
            editor: <EditorInstagramAoA />,
            validation: (creation: Creation) => true
        },
        "instagram_w2l_traffic": {
            name: "instagram_w2l_traffic",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramW2L"
                    defaultMessage="Instagram Web to Learn - Traffic" />,
            editor: <EditorInstagramAoA />,
            validation: (creation: Creation) => true
        },
        "instagram_w2l_conversion": {
            name: "instagram_w2l_conversion",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramW2LEvent"
                    defaultMessage="Instagram Web to Learn - Conversion" />,
            editor: <EditorInstagramAoA />,
            validation: (creation: Creation) => true
        },
    },
    "creative": {
        "leads_ad": {
            name: "leads_ad",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.leadAd"
                    defaultMessage="Lead Ad" />,
            editor: <EditorLeadAd />,
            validation: (creation: Creation) => true
        },
        "instagram-lead_ads": {
            name: "instagram-lead_ads",
            editorTitle:
                <FormattedMessage
                    id="tools.creationEditor.title.post.instagramLeadAd"
                    defaultMessage="Instagram Lead Ad" />,
            editor: <EditorInstagramAoA />,
            validation: (creation: Creation) => true
        }
    },
}

export const GetEditorConfigByType = (_type: CreationType) => {

    const [type, subtype] = _type;

    if (!subtype) return EditorConfigByType[type];

    return EditorConfigByType[type][subtype];

}

