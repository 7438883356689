import {
  BotProps,
  BotSelector,
  BotsSlice,
  GetBot,
} from "modules/bot/containers/BotsSlice";
import { useEffect, useState } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { BotPreview } from "modules/bot/components/posts-list-item/options/form/preview/BotPreview";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import "./BotForm.less";
import { BotEditor } from "./BotEditor";
import { Spin } from "antd";
import Center from "components/common/Center";

type Props = {
  botId: number;
};

type UpdatedBot = {
  page_post_id: string;
  type: string;
  subscribers_list_hash?: string;
  sms_api_group_id?: string;
  integration_group_external_id?: string;
  keyword: string;
  message: string;
  success_message: string;
  error_message: string;
  confirmations?: any[];
};

export const BotUpdate = (props: Props) => {
  const getBot = useAPI(GetBot(props.botId), true);
  const bot: BotProps = useSelectorWithParams([BotSelector, props.botId]);
  const [integration, setIntegration] = useState<string>("private_replies");
  const [botBasedOn, setBotBasedOn] = useState(
    bot?.keyword != null ? "keyword" : "all-comments"
  );
  const [updatedBotData, setUpdatedBotData] = useState<UpdatedBot>({
    page_post_id: bot?.page_post_id,
    type: bot?.integrations[0]?.projectIntegration?.type,
    integration_group_external_id:
      bot?.integrations[0]?.integration_group_external_id,
    keyword: bot?.keyword,
    message: bot?.message,
    success_message: bot?.success_message,
    error_message: bot?.error_message,
  });

  useEffect(() => {
    if (bot?.integrations !== undefined && bot?.integrations.length > 0) {
      if (bot?.integrations[0]?.projectIntegration?.type === "mailer-lite") {
        setIntegration("mailer-lite");
      } else if (
        bot?.integrations[0]?.sms_api_group_id ||
        bot?.integrations[0]?.projectIntegration?.type === "sms-api"
      ) {
        setIntegration("sms-api");
      } else if (
        bot?.integrations[0]?.subscribers_list_hash ||
        bot?.integrations[0]?.projectIntegration?.type === "fresh-mail"
      ) {
        setIntegration("fresh-mail");
      }
    }
  }, []);

  if(getBot.loading) return <Center><Spin size="large"/></Center>

  return (
    <>
      <div className="bot-form">
        <RelaxMultiform
          name="botEdit"
          callAPI={{
            url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/lead-bot/${props.botId}`, 1),
            method: "PATCH",
            successDispatch: BotsSlice.actions.update,
          }}
          finalizeSubmit={(value: any) => {
            if (value.confirmations) {
              const message = value.confirmations[0].message
                ? value.confirmations[0].message
                : updatedBotData.confirmations?.[0]?.message ||
                  bot?.confirmations[0]?.message;
              const keyword = value.confirmations[0].keyword
                ? value.confirmations[0].keyword
                : updatedBotData.confirmations?.[0]?.keyword ||
                  bot?.confirmations[0]?.keyword;
              const error_message = value.confirmations[0].error_message
                ? value.confirmations[0].error_message
                : updatedBotData.confirmations?.[0]?.error ||
                  bot?.confirmations[0]?.error_message;

              value = {
                ...value,
                confirmations: [{ error_message, message, keyword, order: 0 }],
              };
            }
            if (value.message) {
              value = { ...value, keyword: updatedBotData.keyword };
            }

            if (value.integrations) {
              const data = value.integrations[0];
              value = {
                type: integration,
                integration_group_external_id:
                data.integration_group_external_id.toString() ||
                data.subscribers_list_hash ||
                data.sms_api_group_id,
              };
            }
            const udpateValue = { ...updatedBotData, ...value };
            setUpdatedBotData(udpateValue);
            return value;
          }}
          initialValues={bot}
        >
          <BotEditor
            integration={integration}
            setIntegration={setIntegration}
            bot={bot}
            botBasedOn={botBasedOn}
            setBotBasedOn={setBotBasedOn}
            actionType="update"
          />
          {window.innerWidth >= 800 ? (
            <BotPreview integration={integration} />
          ) : (
            ""
          )}
        </RelaxMultiform>
      </div>
    </>
  );
};
