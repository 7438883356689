import { SubscriptionPurchaseInitialOptions } from "components/purchases/subscriptionPurchase/PurchaseSubscription";
import { Subscription } from "./subscriptionTypes";

export const isSubscriptionActive = (subscription: Subscription):boolean => {

    if (!subscription) return false;
    if (subscription.status === "active") return true;
    if (subscription.status === "cancelled" || subscription.status === "canceled") {
      return new Date(subscription.ends_at) > new Date();
    }

    return false;
};


export const isSubscriptionValid = (subscription: any) => {
    if (!subscription) return false;

    //inactive or expired 
    if (subscription.status === "cancelled" || subscription.status === "canceled") {
        const isStillValid = new Date(subscription.ends_at) > new Date();
        if (!isStillValid) return false;
    } else if (subscription.status !== "active") return false;

    //no roles
    if (!subscription.roles || subscription.roles.length === 0) return false;

    //no projects
    if (!subscription.projects || subscription.projects.length === 0) return false;

    return true;
};

export const loadSubscriptionPurchaseInitialOptions = ({projectId, product}:{projectId: string, product: string}):SubscriptionPurchaseInitialOptions => {
    
    console.log("loading initial options", projectId, product)
  
    const lsSettings = localStorage.getItem("selectedOffer") ? JSON.parse(localStorage.getItem("selectedOffer") as string) : null;

    const initial:SubscriptionPurchaseInitialOptions = {
        projectId: projectId,
        product: product,
    }

    if (lsSettings) {
        if (lsSettings.product && !initial.product) initial.product = lsSettings.product;
        if (lsSettings.projectId && !initial.projectId) initial.projectId = lsSettings.projectId;
        initial.offerId = lsSettings.offerId;
        initial.promoCode = lsSettings.promoCode;
        initial.partnerCode = lsSettings.partnerCode;
        initial.showAll = lsSettings.showAll;

        //remove from local storage but keep the partner code
        localStorage.removeItem("selectedOffer");
        if (lsSettings.partnerCode) localStorage.setItem("selectedOffer", JSON.stringify({partnerCode: lsSettings.partnerCode}));

    }

    return(initial);
}
