import { Alert } from "antd";
import React, { Component } from "react";
import FormattedMessage from "components/common/FormattedMessage";

class BotInformations extends Component {
  render() {
    return (
      <>
        <Alert
          message={
            <FormattedMessage
              id="panel.containers.user.details.phone.title"
              defaultMessage="Thank you."
            />
          }
          description={
            <FormattedMessage
              id="panel.containers.onboarding.details.phone.thanks"
              defaultMessage="We will contact you within 2 days."
            />
          }
          type="info"
          showIcon
        />
      </>
    );
  }
}

export default BotInformations;
