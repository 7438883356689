import React, { ReactNode } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { SuccessThumbIcon } from "modules/panel/components/ft-icons";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { userSelector } from "modules/panel/config/selectors/user";
import { HorizontalCenter } from "components/common/Center";

const SuccessPage: React.FunctionComponent<{ heading?: string | ReactNode }> = 
  ({ children, heading }) => {
    
    const user = useSelectorWithParams(userSelector)

    return (
    <div className="success-page">
      <HorizontalCenter>
        <SuccessThumbIcon />
      </HorizontalCenter>
      <h3 className="heading">
        {heading || (
          <FormattedMessage
            id="components.successPage.goodJob"
            defaultMessage="Good job {userName}!"
            values={{
              userName: user?.first_name,
            }}
          />
        )}
      </h3>
      {children}
    </div>
  );}

export default SuccessPage;
