import React from "react";
import { LayoutRouteConfig } from "providers/routing/layouts/layouts";
import { Layout } from "providers/routing/layouts/templates/templates";
import FormattedMessage from "components/common/FormattedMessage";
import Helper from "providers/routing/routes/helper";
import BotInformations from "modules/bot/pages/informations";
import { PostsListWithBots } from "modules/bot/containers/PostListWithBots";
import { BotsList } from "modules/bot/containers/BotsList";
import { faComments, faList, faMessage } from "@fortawesome/pro-light-svg-icons";
import { AwesomeLarge } from "components/common/AwesomeIcon";
import PageChat from "../components/pageChat/PageChat";

export const INSTRUCTION = "instruction",
  ONBOARDING_INSTRUCTION = "onboardingInstruction",
  INTEGRATIONS = "integrations",
  POSTS_WITH_BOTS_LIST = "postsWithBots",
  BOTS_LIST = "list",
  CHAT = "chat";

export const APP_PATH = "bot";

const routes: LayoutRouteConfig[] = [
  {
    key: INSTRUCTION,
    path: "/instruction",
    name: <FormattedMessage id="apps.bot.routing.name" defaultMessage="Bot" />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage id="apps.bot.routing.title" defaultMessage="Bot" />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.bot.routing.subtitle"
        defaultMessage="Bot details"
      />
    ),
    content: BotInformations,
  },
  {
    key: ONBOARDING_INSTRUCTION,
    path: "/onboarding/instruction",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: BotInformations,
  },
  {
    key: POSTS_WITH_BOTS_LIST,
    path: "/posts",
    name: (
      <FormattedMessage
        id="apps.bot.routing.list.name"
        defaultMessage="Your posts and ads"
      />
    ),
    layout: Layout.Panel,
    icon: <AwesomeLarge icon={faList} />,
    title: (
      <FormattedMessage
        id="apps.bot.routing.list.title"
        defaultMessage="Your posts and ads"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.bot.routing.list.title"
        defaultMessage="Choose what post you want to publish."
      />
    ),
    content: PostsListWithBots,
    fullHeight: true,
    mainHasBackground: false,
  },
  {
    key: BOTS_LIST,
    path: "/list",
    name: (
      <FormattedMessage
        id="apps.bot.routing.botsList.name"
        defaultMessage="Your bots"
      />
    ),
    layout: Layout.Panel,
    icon: <AwesomeLarge icon={faMessage} />,
    title: (
      <FormattedMessage
        id="apps.bot.routing.botsList.title"
        defaultMessage="Your bots"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.bot.routing.botsList.title"
        defaultMessage="List of all your bots attached to page and posts"
      />
    ),
    content: BotsList,
    fullHeight: true,
    mainHasBackground: false,
  },
  {
    key: CHAT,
    path: "/chat",
    name: (
      <FormattedMessage
        id="apps.bot.routing.chat.name"
        defaultMessage="Chat"
      />
    ),
    layout: Layout.Panel,
    icon: <AwesomeLarge icon={faComments} />,
    title: (
      <FormattedMessage
        id="apps.bot.routing.chat.title"
        defaultMessage="Chat"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.bot.routing.chat.title"
        defaultMessage="Chat with your bot"
      />
    ),
    content: PageChat,
    fullHeight: true,
    mainHasBackground: false,
  }
  // {
  //   key: INTEGRATIONS,
  //   path: "/integrations",
  //   icon: <IntegrationsIcon />,
  //   name: (
  //     <FormattedMessage
  //       id="apps.bot.routing.integrations.name"
  //       defaultMessage="Integrations"
  //     />
  //   ),
  //   layout: Layout.Panel,
  //   title: (
  //     <FormattedMessage
  //       id="apps.bot.routing.integrations.title"
  //       defaultMessage="Integrations"
  //     />
  //   ),
  //   subtitle: (
  //     <FormattedMessage
  //       id="apps.bot.routing.integrations.title"
  //       defaultMessage="Integrate your bot with external applications"
  //     />
  //   ),
  //   content: Integrations,
  //   fullHeight: true,
  //   mainHasBackground: false,
  // },
];

export default routes;
export const getUrl = (routeKey: string) =>
  Helper.getPath(APP_PATH, routes, routeKey);
