import { Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "components/inputs/DatePicker";
import dayjs, { Dayjs } from 'dayjs';

export type DateTimeInputProps = {
    disabledDate?: ((current: Dayjs) => boolean) | boolean,
    allowedPeriodStart?: Dayjs,
    allowedPeriodEnd?: Dayjs,
    value?: any;
    onChange?: (value: any) => void;
    onBlur?: (value: any) => void;
    UTC?: boolean;
}

export default function DateTimeInput (props: DateTimeInputProps) {

    const utc = !!props.UTC

    const handleChange = (newDateTime: any) => {
        const rounded = newDateTime.minute(Math.round(newDateTime.utc().minute() / 15) * 15).second(0).millisecond(0);
        const adj = utc ? rounded.utc() : rounded.local()
        props.onChange && props.onChange(adj)
    }

    const disabledDateFunc = (min?: Dayjs, max?: Dayjs) => (current: Dayjs):boolean => {
        let allowed = true;
        if (min && current.isBefore(min)) allowed = false;
        if (max && current.isAfter(max)) allowed = false;
        return !allowed;
    };

    const disableDate = () => {
        if (!props.disabledDate) return undefined;
        if (props.disabledDate === true) return disabledDateFunc(props.allowedPeriodStart, props.allowedPeriodEnd);
        return props.disabledDate;
    }

    const getAdj = () => {
        if (props.value) {
            if (utc) return dayjs(props.value).utc(true).local()
            return dayjs(props.value)
        }
        if (utc) return dayjs().utc().local()
        return dayjs()
    }

    const adj = getAdj()

    return (
        <Row style={{columnGap: "10px"}}>
            <DatePicker 
            value={adj}
            disabledDate={disableDate()}
            onChange={handleChange}
            onBlur={props.onBlur && props.onBlur}
            style={{flexGrow: 1}}
            format="L"
            />
            <DatePicker.TimePicker 
            value={adj}
            minuteStep={15} 
            showNow={false}
            disabledDate={disableDate()}
            format="HH:mm"
            onChange={handleChange}
            onBlur={props.onBlur && props.onBlur}
            style={{flexGrow: 1}} />
        </Row>
    )
}