import { useState } from "react";

export const getGeocoder = () => {
    if (!window.google?.maps?.Geocoder) return null;
    if (window.geocoder) return window.geocoder;
    window.geocoder = new window.google.maps.Geocoder();
    return window.geocoder;
}

export function useGoogleGeocoder(props:any) {

    const [results, setResults] = useState<any>(null);
    const geocoder = getGeocoder();

    const searchSingle = async (term:string) => {
        if (!geocoder) return;
        const res = await geocoder.geocode({address: term});
        setResults(res?.results);
        return res;
    }

    const searchByPoint = async (lat:number, lng:number) => {
        if (!geocoder) return;
        const res = await geocoder.geocode({location: {lat, lng}});
        setResults(res?.results);
        return res;
    }

    const clear = () => {
        setResults(null);
    }

    return {
        geocoder,
        search: searchSingle,
        searchByPoint,
        results,
        clear
    }

}