import { useEffect, useMemo, useState } from "react"
import useAPIWithIncrementsNoPages from "services/hooks/useAPIWithIncrementsNoPages"
import { ProjectBotsSelector, GetBots, SortBotsListByDate, GetBot } from "./BotsSlice"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import BotsListEntry from "./BotsListEntry"
import { Spin, Tooltip } from "antd"
import FormattedMessage from "components/common/FormattedMessage"
import InfinityListQuery from "tools/infinityList/infinityListQuery"
import InfinityList from "tools/infinityList/infinityList"
import { BotsHeadings } from "./BotsHeadigs"
import { HorizontalCenter } from "components/common/Center"
import { CreateBotBtn } from "../components/posts-list-item/options/CreateBotBtn"
import { CreatePostIcon } from "modules/panel/components/ft-icons"

export const BotsList = () => {
    const [query, setQuery] = useState<any>()
    const { startWithNewQuery, getNextIncrement, loading, emptyResponses } = useAPIWithIncrementsNoPages({
        callAPI: GetBots,
        query: query
    })

    useEffect(() => {
        GetBotsList();
    }, [query]);

    const botsSelector = useSelectorWithParams(ProjectBotsSelector)

    const onQueryChange = (query: any) => {
        const normalizedQuery: any = {
            "date[from]": query.start.format("YYYY-MM-DD"),
            "date[to]": query.end.format("YYYY-MM-DD"),
            "sort": query.sort || "created_at",
        }

        GetBotsList();
        setQuery(normalizedQuery);
    }

    const sortedBotsList = useMemo(() => {
        if (!botsSelector) return [];

        const filteredBotsList = botsSelector;

        if (!query || query.sort) return botsSelector;
        if (query.sort === "created_at") {
            return SortBotsListByDate(filteredBotsList, false);
        }
        if (query.sort === "-created_at") {
            return SortBotsListByDate(filteredBotsList, true);
        }

        return filteredBotsList;
    }, [botsSelector])

    const renderItem = (item: any) => {
        return <BotsListEntry item={item} />
    }

    const GetMore = () => {
        getNextIncrement((q) => {
            if (!q) {
                return;
            }
            const oldQ = q;
            const previousFrom = new Date(oldQ["date[from]"]);
            const previousTo = new Date(oldQ["date[to]"]);

            let newFrom: Date;
            let newTo: Date;

            if (query?.sort === "created_at") {
                newFrom = new Date(previousTo.getTime());
                newTo = new Date(newFrom.getTime() + 1000 * 60 * 60 * 24 * 7);
            } else {
                newFrom = new Date(previousFrom.getTime() - 1000 * 60 * 60 * 24 * 7);
                newTo = new Date(previousFrom.getTime());
            }

            return {
                ...oldQ,
                "date[from]": newFrom.toISOString().split("T")[0],
                "date[to]": newTo.toISOString().split("T")[0]
            };
        })
    }

    // const handleLoadNextPage = () => {
    //     if (emptyResponses < 3) GetMore();
    // }

    const GetBotsList = () => {
        if (!query) return;
        startWithNewQuery(query);
    }

    const extra = useMemo(() => {

        if (botsSelector && botsSelector.length > 0 && loading) return (
            <HorizontalCenter style={{ padding: "30px" }} key="loading">
                <Spin />
            </HorizontalCenter>
        )
        if (emptyResponses >= 3) return (
            <HorizontalCenter style={{ padding: "30px" }} key="nothing">
                <h3>
                    <FormattedMessage
                        id="posts.list.error.nothingMore"
                        defaultMessage="Nothing more to show. Please change the date range."
                    />
                </h3>
            </HorizontalCenter>
        )
        return null
    }, [emptyResponses, loading])

    const queryInput = useMemo(() => {
        return (
            <InfinityListQuery
                onChange={onQueryChange}
                onReload={GetBotsList}
            >
                    <div style={{ height: "100%", display: "grid", placeItems: "center" }}>
                        <CreateBotBtn
                            btn={
                                <Tooltip title={
                                    <FormattedMessage
                                        id="tools.bots.actions.new.tooltip"
                                        defaultMessage="New Bot"
                                    />
                                }>
                                    <span style={{ marginTop: "5px" }}>
                                        <CreatePostIcon />
                                    </span>
                                </Tooltip>
                            }
                        />
                    </div>
            </InfinityListQuery>
        )
    }, [query])

    return (
        <>

            <div className="infinity-wrapper">
                {queryInput}
                <InfinityList
                    headers={BotsHeadings}
                    items={sortedBotsList}
                    loading={loading}
                    //loadMoreData={handleLoadNextPage}
                    itemSkeleton={() => "skeleton"}
                    renderItem={renderItem}
                    additionalItems={[
                        extra
                    ]}
                />
            </div >
        </>
    )
}

