import { Form, FormProps as AntFormProps, FormInstance, Button } from "antd";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import React, { useEffect, useState, useRef, useContext, useMemo, Children } from "react";

export type SubformSettigs = {
    addToAPI?: boolean, //Should data from this form be added to the API call
    ignore?: boolean, //Should this form be ignored (will not be available in state)
    stopOnError?: boolean, //Errors in this form will prevent the main form from calling api. 
}

export type MultiformProps = {
    name?: string,
    onSubformChange?: any,
    onSubformSubmit?: any, 
    onSubmit?:any,
    submitOnChange?: boolean,
    callAPI?: callAPIProps | ((initial:boolean, body?:any, props?:any) => callAPIProps), // Initial Props for API call
    children?: any,
    multiformConfig?: {[key: string]: SubformSettigs},
    initialValues?: any,
    initialValuesSelector?: any, //Selector for initial values;
    ignoreChangesToInitialValues?: boolean,
    finalizeSubmit?: (d: any) => any, //Callback to finalize data before send

}

const RelaxMultiformContext = React.createContext({});
RelaxMultiformContext.displayName = "RelaxMultiformContext";
export const useRelaxMultiform = (): any => useContext(RelaxMultiformContext);

export default function RelaxMultiform({...props}:MultiformProps) {

    return (
        <RelaxMultiformContext.Provider value={{
            multiformProps: props,
        }}>
            {props.children}
        </RelaxMultiformContext.Provider>
    )

}