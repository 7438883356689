import {createSlice, createSelector} from '@reduxjs/toolkit';
import { callAPIProps } from 'services/hooks/useAPI';
import _ from 'lodash';
import { currentProjectIdSelector } from 'services/store/scopeSlice';

export type WebsitePagesState = any;

const initialState: WebsitePagesState = {

}


export const websitePagesSlice = createSlice({
    name: "pages",
    initialState, 
    reducers: {
        get: (state, action) => {
            if (!state[action.payload.projectId]) state[action.payload.projectId] = {};
            if (action.payload.data && action.payload.data.forEach) {
                const sorted = _.sortBy(action.payload.data, "order")
                sorted.forEach((r:any) => {
                    state[action.payload.projectId][r.id] = r;
                })
            }
        },
        updatePage: (state, action) => {
            state[action.payload.projectId][action.payload.data.id] = {...state[action.payload.projectId][action.payload.data.id],...action.payload.data};
        },
        addPage: (state, action) => {
            state[action.payload.projectId][action.payload.data.id] = action.payload.data;
        },
        addPageDescription: (state, action) => {
            state[action.payload.projectId][action.payload.additional.pageId].websiteCustomPageDescriptions[action.payload.data.id] = action.payload.data;
        },
        removePage: (state, action) => {
            delete state[action.payload.projectId][action.payload.additional.pageId]
        },
    }
})


export const GetPages = (websiteId:string | number):callAPIProps => ({
    url: ({ getApiUrl, language, projectId}: any) => getApiUrl(`websites/${websiteId}/custom-pages`,1),
    method: "GET",
    successDispatch: websitePagesSlice.actions.get,
})

export const AddPage = (websiteId:string | number):callAPIProps => ({
    url: ({getApiUrl, projectId}) => getApiUrl(`websites/${websiteId}/custom-pages`,1),
    method: "POST",
    successDispatch: websitePagesSlice.actions.addPage,
})

export const AddPageDescription = (websiteId:string | number, pageId: string | number):callAPIProps => ({
    url: ({getApiUrl, projectId}) => getApiUrl(`websites/${websiteId}/custom-pages/${pageId}/description`,1),
    method: "POST",
    successDispatch: websitePagesSlice.actions.addPageDescription,
    passToDispatcher: {pageId: pageId},
})


export const RemovePage = (websiteId:string | number, pageId: string | number):callAPIProps => ({
    url: ({getApiUrl, projectId}) => getApiUrl(`websites/${websiteId}/custom-pages/${pageId}`,1),
    method: "DELETE",
    successDispatch: websitePagesSlice.actions.removePage,
    passToDispatcher: {pageId: pageId},
})

export const ProjectWebsitePagesSelector = createSelector([
    (state:any) => state?.siteGenerator.pages,
    currentProjectIdSelector
],
    (state:any, projectId: any) => state && state[projectId]
)

export const WebsitePageSelector = createSelector([
    ProjectWebsitePagesSelector,
    (state:any, pageId: any) => pageId
], (pages: any, pageId: any) => pages && pages[pageId])

export default websitePagesSlice.reducer;