import StandardButton from "components/common/StandardButton";
import React, { Suspense, useEffect, useImperativeHandle } from "react";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import EmojiPicker from "./TextTools/EmojiPicker";
import InsertKeyword from "./TextTools/InsertKeyword";
import ReplaceFromLexicon from "./TextTools/ReplaceFromLexicon";
import FormattedMessage from "components/common/FormattedMessage";


//const ToolEmojiPicker = React.lazy(() => import("tools/textEditor/TextTools/EmojiPicker"));

export type TextToolConifg = {
    tool: string;
    onOpen?: () => void;
    onClose?: () => void;
    onInsert?: (text: string) => void;
    props: any;
}

export const TextToolsDrawer = React.forwardRef((props: any, ref: any) => {

    const [drawerRef, open, close, isOpen] = useStandardDrawer();
    const toolConifg = React.useRef<any>(null);
    const [toolComponent, setToolComponent] = React.useState<any>(null);

    const handleOpen = (newToolConfig: TextToolConifg) => {

        //console.log("ToolConfig", newToolConfig)
        toolConifg.current = newToolConfig;

        if (newToolConfig.onOpen) newToolConfig.onOpen();

        if (newToolConfig.tool === "emoji") setToolComponent(
            <Suspense fallback={<div>Loading...</div>}>
                <EmojiPicker onPick={(emoji:any) => handleTextInsert(emoji?.emoji)} />
            </Suspense>
        )

        if (newToolConfig.tool === "keywords") setToolComponent(
            <InsertKeyword onPick={(keyword:any) => handleTextInsert(keyword)} />
        )

        if (newToolConfig.tool === "replaceFromLexicon") setToolComponent(
            <ReplaceFromLexicon {...newToolConfig.props} onSelect={(text:any) => handleTextInsert(text)} />

        )

        open();

    }

    const handleTextInsert = (text: string) => {

        if (!toolConifg.current) return;  
        if (toolConifg.current.onInsert) {
            toolConifg.current.onInsert(text);
        }

    }

    const handleClose = () => {

        if (toolConifg.current && toolConifg.current.onClose) {
            toolConifg.current.onClose();
        }
        close();

    }



    useImperativeHandle(ref, () => ({
        open: handleOpen,
        close: handleClose,
    }));

    return (
        <>  
            <StandardDrawer
                ref={drawerRef}
                width={toolConifg.current?.width || "400px"}
                layout="standard"
                mask={true}
                title={<FormattedMessage 
                    id={"tools.textEditor.drawer.title"} 
                    defaultMessage={"Tools"}                    
                />}
                zIndex={120}
                onClose={handleClose}
            >
                {toolComponent}
            </StandardDrawer>
        </>
    );
});

const TextToolsContext = React.createContext<any>(null);
TextToolsContext.displayName = "Text Tools Context";
export const useTextTools = () => React.useContext(TextToolsContext);

export default function TextTools(props:any) {

    const textToolsRef = React.useRef<any>(null);

    const openTextTools = (toolConfig: TextToolConifg) => {
        textToolsRef.current?.open(toolConfig);
    }

    const closeTextTools = () => {
        textToolsRef.current?.close();
    }

    return (
        <TextToolsContext.Provider value={{
            openTextTools,
            closeTextTools,
        }}>
            {props.children}
            <TextToolsDrawer ref={textToolsRef} />
        </TextToolsContext.Provider>
    );

}