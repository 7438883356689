import { useEffect, useMemo, useState } from "react";
import {
  FreshMailConfig,
  MailerLiteConfig,
  SimpleReplyConfig,
  SmsApiConfig,
} from "./BotBtnConfig";
import FormattedMessage from "components/common/FormattedMessage";
import { Col, Input, Radio, Row, Spin } from "antd";
import RelaxField from "tools/relaxForm/RelaxField";
import "./BotForm.less";
import { BotProps } from "modules/bot/containers/BotsSlice";
import { BotIntegrationRadio } from "./BotIntegrationRadio";

type Props = {
  hideStatus?: boolean;
  bot?: BotProps;
  botBasedOn: string;
  setBotBasedOn: (data: string) => void;
  integration?: string;
  setIntegration: (data: string) => void;
  actionType: "update" | "create";
};

export const BotEditor = (props: Props) => {
  const selectedComponent = useMemo(() => {
    switch (props.integration) {
      case "private_replies":
        return <SimpleReplyConfig hideStatus={props.hideStatus} />;
      case "fresh-mail":
        return (
          <FreshMailConfig
            name={
              props.bot
                ? props.bot.integrations[0]?.subscribers_list_hash
                  ? ["integrations", 0, "subscribers_list_hash"]
                  : ["integrations", 0, "integration_group_external_id"]
                : "integration_group_external_id"
            }
            hideStatus={props.hideStatus}
          />
        );
      case "sms-api":
        return (
          <SmsApiConfig
            name={
              props.bot
                ? props.bot.integrations[0]?.sms_api_group_id
                  ? ["integrations", 0, "sms_api_group_id"]
                  : ["integrations", 0, "integration_group_external_id"]
                : "integration_group_external_id"
            }
            hideStatus={props.hideStatus}
          />
        );
      case "mailer-lite":
        return (
          <MailerLiteConfig
            name={
              props.bot
                ? ["integrations", 0, "integration_group_external_id"]
                : "integration_group_external_id"
            }
            hideStatus={props.hideStatus}
          />
        );
      default:
        return null;
    }
  }, [props.integration, props.bot]);

  return (
    <>
      <div className="form">
        <BotIntegrationRadio
         integration={props.integration}
         setIntegration={props.setIntegration}
         actionType={props.actionType}
        />
        <Row>
          <Col md={24} lg={16}>
            <div className="ant-col ant-form-item-label">
              <label className="ant-form-item-no-colon">
                <FormattedMessage
                  id="modules.bot.form.activateBotBasedOn"
                  defaultMessage="Activate bot based on"
                />
              </label>
            </div>
            <Radio.Group defaultValue={props.botBasedOn} buttonStyle="solid">
              <Radio.Button
                onClick={() => props.setBotBasedOn("all-comments")}
                value={"all-comments"}
              >
                <FormattedMessage
                  id="components.forms.activatedOn.allComments"
                  defaultMessage="All comments"
                />
              </Radio.Button>
              <Radio.Button
                onClick={() => props.setBotBasedOn("keyword")}
                value={"keyword"}
              >
                <FormattedMessage
                  id="components.forms.activatedOn.keyword"
                  defaultMessage="Keyword"
                />
              </Radio.Button>
            </Radio.Group>
          </Col>

          <Col md={24} lg={8}>
            {props.botBasedOn === "keyword" && (
              <>
                <RelaxField
                  style={{ margin: "30px 0 0 10px" }}
                  name="keyword"
                  className="ant-form-item-no-colon"
                  hideStatus={props.hideStatus}
                  label={
                    <FormattedMessage
                      id="components.forms.activatedOn.keyword"
                      defaultMessage="Keyword"
                    />
                  }
                  tooltip={
                    <FormattedMessage
                      id="components.forms.activatedOn.keyword.tooltip"
                      defaultMessage="Keyword"
                    />
                  }
                >
                  <Input />
                </RelaxField>
              </>
            )}
          </Col>
        </Row>
        {selectedComponent}
      </div>
    </>
  );
};
