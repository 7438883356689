import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Input, Row, Select, Space } from "antd";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import { useEffect, useMemo, useRef, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { AddPageDescription, RemovePage, WebsitePageSelector, websitePagesSlice } from "modules/site-generator/reducers/websitePagesSlice";
import { StandardFullColumn, StandardHalfFullColumn, StandardRow, StandardThirdColumn } from "components/layout/StandardGrid";
import HtmlEditor from "tools/textEditor/HtmlEditor";
import RelaxForm from "tools/relaxForm/RelaxForm";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import { HorizontalCenter } from "components/common/Center";
import StandardButton from "components/common/StandardButton";
import useRelaxData from "tools/relaxForm/useRelaxData";
import RelaxControls from "tools/relaxForm/RelaxControls";
import { WebsiteSelector } from "modules/site-generator/reducers/websiteSlice";

const defaultPageDescription = {
    name: "Nazwa Treści",
    content: "Treść Strony",
    language_code: "pl"
}

export type WebsitePageProps = {
    page?: any;
    website?: any;
    onPageDelete?: (pageId?: string) => void;
}

export const WebsitePageListEntry = (props: WebsitePageProps) => {

    const [delConfRef, delConfOpen, delConfClose] = useStandardModal();
    const [ref, open, close] = useStandardDrawer();
    const { call, data } = useAPI({});
    const descrFormRef: any = useRef();

    const displayDeleteConfirmation = () => {
        delConfOpen();
    }

    const handleRemove = () => {
        delConfClose();
        call(RemovePage(props.website.id, props.page.id));
        props.onPageDelete && props.onPageDelete(props.page.id);
    }

    const handleSave = () => {
        if (descrFormRef?.current?.submit) descrFormRef.current.submit();
    }

    return (
        <>
            <Card
                actions={[
                    <EditOutlined key="edit" onClick={open} />,
                    <DeleteOutlined key="delete" onClick={displayDeleteConfirmation} />
                ]}
                style={{ width: "200px", maxWidth: "100vw" }}
                title={props.page?.title}
            >
                <div>
                    <FormattedMessage
                        id="apps.websites.customPages.page.status"
                        defaultMessage="Status"
                    />: {props.page?.status}
                </div>
                <div>
                    <FormattedMessage
                        id="apps.websites.customPages.page.place"
                        defaultMessage="Location"
                    />: {props.page?.place}
                </div>
                <div>
                    <FormattedMessage
                        id="apps.websites.customPages.page.type"
                        defaultMessage="Type"
                    />: {props.page?.type}
                </div>
            </Card>
            <StandardDrawer
                ref={ref}
                title={<FormattedMessage
                    id="apps.websites.customPages.page.edit"
                    defaultMessage="Edit Page"
                />}
                width="1500px"
                layout="standard"
                footer={
                    <Space>
                        <StandardButton key="save" type="primary" onClick={handleSave}><FormattedMessage
                            id="common.save"
                            defaultMessage="Save"
                        /></StandardButton>
                        <StandardButton key="delete" onClick={displayDeleteConfirmation}><FormattedMessage
                            id="common.delete"
                            defaultMessage="Delete"
                        /></StandardButton>
                    </Space>
                }
            >
                <WebsitePage page={props.page} onPageDelete={close} descRef={descrFormRef} />
            </StandardDrawer>
            <StandardModal
            ref={delConfRef}
            onOk={handleRemove}
            >
                <FormattedMessage
                    id="apps.websites.customPages.page.delete.confirm"
                    defaultMessage="Are you sure you want to delete this page?"
                />
                <HorizontalCenter>
                <h1>
                    {props.page?.title}
                </h1>
                </HorizontalCenter>
            </StandardModal>
        </>
    )


}


const WebsitePage = (props: (WebsitePageProps & { descRef: any })) => {

    const [type, setType] = useState("html");
    const { call, data } = useAPI({});
    const website = useSelectorWithParams([WebsiteSelector]);

    const handleTypeChange = (type: any) => {
        setType(type)
    }

    useEffect(() => {
        if (!props.page) return;
        if (!props.page.websiteCustomPageDescriptions || props.page.websiteCustomPageDescriptions.length === 0) {
            call({ ...AddPageDescription(website.id, props.page.id), body: defaultPageDescription });
        }
    }, [props.page]);

    return (
        <>
            <RelaxMultiform
                callAPI={{
                    url: ({ getApiUrl, projectId }) => getApiUrl(`websites/${website.id}/custom-pages/${props.page.id}`),
                    method: "PATCH",
                    auth: true,
                    successDispatch: websitePagesSlice.actions.updatePage,
                    passToDispatcher: { pageId: props.page.id },
                }}
                initialValues={props.page}
                ignoreChangesToInitialValues
                name="rules">
                <StandardRow>
                    <StandardFullColumn>
                        <RelaxSingleField
                            name={["title"]}
                            label={<FormattedMessage
                                id="apps.websites.customPages.page.title"
                                defaultMessage="Title"
                            />}
                        >
                            <Input />
                        </RelaxSingleField>
                    </StandardFullColumn>
                </StandardRow>
                <StandardRow>
                    <StandardThirdColumn>
                        <RelaxSingleField
                            name={["type"]}
                            label={<FormattedMessage
                                id="apps.websites.customPages.page.type"
                                defaultMessage="Type"
                            />}
                            onChange={handleTypeChange}
                        >
                            <Select defaultValue="html">
                                <Select.Option value="html">HTML</Select.Option>
                                <Select.Option value="iframe_url">iFrame</Select.Option>
                            </Select>
                        </RelaxSingleField>
                    </StandardThirdColumn>
                    <StandardThirdColumn>
                        <RelaxSingleField
                            name={["status"]}
                            label={<FormattedMessage
                                id="apps.websites.customPages.page.status"
                                defaultMessage="Status"
                            />}
                            onChange={handleTypeChange}
                        >
                            <Select defaultValue="draft">
                                <Select.Option value="active"><FormattedMessage
                                    id="apps.websites.customPages.page.status.active"
                                    defaultMessage="Active"
                                /></Select.Option>
                                <Select.Option value="draft"><FormattedMessage
                                    id="apps.websites.customPages.page.status.draft"
                                    defaultMessage="Draft"
                                /></Select.Option>
                            </Select>
                        </RelaxSingleField>
                    </StandardThirdColumn>
                    <StandardThirdColumn>
                        <RelaxSingleField
                            name={["place"]}
                            label={<FormattedMessage
                                id="apps.websites.customPages.page.place"
                                defaultMessage="Place"
                            />}
                        >
                            <Select defaultValue="root">
                                <Select.Option value="root"><FormattedMessage
                                    id="apps.websites.customPages.page.place.root"
                                    defaultMessage="Root"
                                /></Select.Option>
                                <Select.Option value="attraction"><FormattedMessage
                                    id="apps.websites.customPages.page.place.attraction"
                                    defaultMessage="Attraction"
                                /></Select.Option>
                                <Select.Option value="special_offer"><FormattedMessage
                                    id="apps.websites.customPages.page.place.specialOffer"
                                    defaultMessage="Special Offer"
                                /></Select.Option>
                            </Select>
                        </RelaxSingleField>
                    </StandardThirdColumn>
                </StandardRow>
            </RelaxMultiform>
            {props.page.websiteCustomPageDescriptions && props.page.websiteCustomPageDescriptions.map((content: any, index: number) => (
                <WebsitePageContent descRef={props.descRef} page={props.page} website={website} content={content} />
            ))}

        </>
    )
}

const WebsitePageContent = (props: { page?: any, website?: any, content?: any, descRef?: any }) => {

    const rd = useRelaxData({});

    if (!props.page) return null;

    return (
        <RelaxMultiform
            callAPI={{
                url: ({ getApiUrl, projectId }) => getApiUrl(`websites/${props.website?.id}/custom-pages/${props.page.id}/description/${props.content.id}`),
                method: "PATCH",
                auth: true,
                //successDispatch: websitePagesSlice.actions.updatePage,
                passToDispatcher: { pageId: props.page.id },
            }}
            initialValues={props.content}
            ignoreChangesToInitialValues
            name="rules">
            <RelaxForm
                name="content"
                layout="vertical"
                ref={props.descRef}
                relaxData={rd}
            >
                <StandardRow>
                    <StandardFullColumn>
                        <RelaxSingleField
                            name={"name"}
                            label={<FormattedMessage
                            id="apps.websites.customPages.page.content"
                            defaultMessage="Name"
                        />}
                        >
                            <Input />
                        </RelaxSingleField>
                    </StandardFullColumn>
                </StandardRow>

                <StandardRow>
                    {props.page.type === "html" && (
                        <StandardFullColumn>
                            <RelaxSingleField
                                name={["content"]}>
                                <HtmlEditor />
                            </RelaxSingleField>
                        </StandardFullColumn>
                    )}
                    {props.page.type === "iframe_url" && (
                        <StandardFullColumn>
                            <RelaxSingleField
                                name={["content"]}
                                label="iFrame URL"
                            >
                                <Input />
                            </RelaxSingleField>
                        </StandardFullColumn>
                    )}
                </StandardRow>
            </RelaxForm>
            <RelaxControls relaxData={rd} />
        </RelaxMultiform>
    )

}