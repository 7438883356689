import { AutoComplete } from "antd";
import { userLanguageSelector } from "modules/panel/config/selectors/user";
import { useMemo } from "react"
import pl from 'react-phone-number-input/locale/pl.json';
import en from 'react-phone-number-input/locale/en.json';
import fr from 'react-phone-number-input/locale/fr.json';
import es from 'react-phone-number-input/locale/es.json';
import ru from 'react-phone-number-input/locale/ru.json';
import { useSelector } from "react-redux";

export const CountrySelect = (props: any) => {

    const language = useSelector(userLanguageSelector);

    const langPack = useMemo(() => {
        switch (language) {
            case "pl":
                return pl;
            case "en":
                return en;
            case "fr":
                return fr;
            case "es":
                return es;
            case "ru":
                return ru;
            default:
                return en;
        }
    }, [language]);

    const countryOptions = useMemo(() => {
        const filteredCountries = Object.keys(langPack)
            .filter(countryCode => countryCode === countryCode.toUpperCase());
        return filteredCountries.map(countryCode => ({
            value: countryCode,
            text: langPack[countryCode],
        }));
    }, [langPack]);

    const getCountryName = (value: string) => {
        const selectedCountry = countryOptions.find(country => country.value === value);
        return selectedCountry ? selectedCountry.text : value;
    };


    return (
        <AutoComplete
            dataSource={countryOptions.map((country: any) => country.text)}
            value={getCountryName(props.value)} 
            onChange={props.onChange}
            onBlur={props.onBlur}
            filterOption={(inputValue, option: any) =>
                option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
            }
            onSelect={value => {
                const selectedCountry = countryOptions.find(country => country.text === value);
                if (selectedCountry) {
                    props.onChange(selectedCountry.value);
                    props.onBlur(selectedCountry.value);
                }
            }}
        />
    );
}

